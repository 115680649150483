import clsx from 'clsx'
import {
  type ChangeEvent,
  type Dispatch,
  MouseEvent,
  type SetStateAction,
} from 'react'

import { FvButton, Icon, Tooltip } from '@fv/client-components'
import { type EquipmentType, type Quote } from '@fv/client-types'

import { type CarrierAppSettings } from '../../hooks/settings/useAppSettings'
import { CheckboxInput } from '../inputs/CheckboxInput'
import { PriceInput } from '../inputs/PriceInput'
import { SelectInput } from '../inputs/SelectInput'
import { TextInput } from '../inputs/TextInput'

type Props = {
  defaultEquipmentType: EquipmentType
  equipmentTypeOptions: Array<{
    value: EquipmentType
    text: string
  }>
  getInputEl: (n: string) => HTMLInputElement | undefined
  isBusy: boolean
  isEditingContractedRate?: boolean
  isSelected: boolean
  quote?: Quote
  quoteSettings?: CarrierAppSettings
  setEditingContractedRate?: Dispatch<SetStateAction<boolean>>
  setSelected: (s: boolean) => void
}

const QuoteFormRow = ({
  defaultEquipmentType,
  equipmentTypeOptions,
  getInputEl,
  isBusy,
  isEditingContractedRate,
  isSelected,
  quote,
  quoteSettings,
  setEditingContractedRate,
  setSelected,
}: Props) => {
  const { _id, amount, equipmentType, method, quoteNum, source } = quote ?? {}
  const { allowAPIQuoteEdit, requiresQuoteNumber = true } = quoteSettings ?? {}
  const inputName = (field: string) => (_id ? `${field}_${_id}` : field)
  const quoteType = quoteTypeLabel(quote)

  const isApiRate = method === 'api'
  const isCarrierRate = !quote || (method === 'manual' && source === 'carrier')
  const isContractedRate = method === 'upload'
  const isShipperRate = method === 'manual' && source === 'shipper'

  const isDisabled =
    isBusy ||
    isShipperRate ||
    (isApiRate && !allowAPIQuoteEdit) ||
    (isContractedRate && !isEditingContractedRate)

  function toggleQuoteSelection(e: ChangeEvent<HTMLInputElement>) {
    const isChecked = e.target.checked
    const quoteNumberEl = getInputEl(inputName('quoteNumber'))

    setSelected(isChecked)

    if (isChecked) quoteNumberEl?.focus()
    else {
      const amountEl = getInputEl(inputName('amount'))
      if (amountEl) amountEl.value = ''
      if (quoteNumberEl) quoteNumberEl.value = ''
    }
  }

  function toggleContractedRateLock() {
    if (isEditingContractedRate) {
      const amountEl = getInputEl(inputName('amount'))
      const equipmentEl = getInputEl(inputName('equipmentType'))
      const quoteNumberEl = getInputEl(inputName('quoteNumber'))

      if (amountEl) amountEl.value = String(amount ?? '')
      if (quoteNumberEl) quoteNumberEl.value = quoteNum ?? ''
      if (equipmentEl) {
        equipmentEl.value = equipmentType ?? defaultEquipmentType
      }
    }

    setEditingContractedRate?.(prev => !prev)
  }

  return (
    <>
      {isContractedRate && isEditingContractedRate && (
        <div className="box mt-4 bg-white">
          <div className="icon-message">
            <Icon
              className="text-fv-orange"
              icon="hand-paper"
              transform="down-1"
            />
            <p className="mb-0">
              This is a contracted rate. Your company has negotiated a
              pre-determined rate for this lane. Unlocking and modifying this
              rate will override your contracted rate. The shipper will then be
              shown the new rate. Select 'Update bid' to proceed or 'X' to
              cancel and keep the contracted rate.
            </p>
          </div>
        </div>
      )}

      <div className="b850:flex-wrap b1500:flex-wrap b1000:flex-nowrap mt-4 flex items-center gap-3">
        <div className="flex items-center gap-x-2">
          <CheckboxInput
            checked={isSelected}
            className={clsx('checkbox', {
              disabled: isBusy || !isCarrierRate,
            })}
            disabled={isBusy || !isCarrierRate}
            label={quoteType + ' quote'}
            name={inputName('selected')}
            onChange={toggleQuoteSelection}
            required={!quote}
          />

          {isShipperRate && (
            <Tooltip label="This quote was entered by the shipper">
              <Icon icon="user-circle" />
            </Tooltip>
          )}

          {isContractedRate && (
            <Tooltip label="This is a contracted rate">
              <Icon
                icon="money-check-alt"
                transform="down-.5"
                className="ml-1"
              />
            </Tooltip>
          )}

          {isApiRate && (
            <Tooltip label="Automatically generated via API">
              <Icon icon="server" />
            </Tooltip>
          )}
        </div>

        <div className="h-0 flex-1 border border-dashed border-b-[#45abd3]" />

        {equipmentTypeOptions.length > 1 && (
          <SelectInput
            className="form-control form-control--select b1500:basis-full b1500:max-w-none b850:basis-full b850:max-w-none b1000:max-w-[14rem] min-w-[12rem] max-w-[14rem]"
            defaultValue={equipmentType ?? defaultEquipmentType}
            disabled={isDisabled}
            name={inputName('equipmentType')}
            onChange={() => {
              const quoteNumberEl = getInputEl(inputName('quoteNumber'))
              quoteNumberEl?.focus()
              setSelected(true)
            }}
            options={equipmentTypeOptions}
          />
        )}

        <div className="input-group input-group--flex b1500:flex-1 mb-0">
          <div className="input-group-addon">
            <Icon icon="hashtag" className="color-dark" />
          </div>
          <TextInput
            className="form-control min-w-[7rem]"
            defaultValue={quoteNum ?? ''}
            disabled={isDisabled}
            maxLength={50}
            name={inputName('quoteNumber')}
            onChange={() => setSelected(true)}
            placeholder="quote #"
            required={isSelected && requiresQuoteNumber}
          />
        </div>

        <div className="input-group input-group--flex b400:basis-full mb-0">
          <div className="input-group-addon">
            <Icon icon="dollar-sign" className="color-dark" />
          </div>
          <PriceInput
            className="form-control b400:w-full w-[5rem]"
            defaultValue={String(amount?.toFixed(2) ?? '')}
            disabled={isDisabled}
            name={inputName('amount')}
            onChange={() => setSelected(true)}
            required={isSelected}
          />

          {isContractedRate && (
            <FvButton
              theme="default"
              className="-ml-1"
              icon={isEditingContractedRate ? 'times' : 'lock'}
              onClick={toggleContractedRateLock}
            />
          )}
        </div>
      </div>
    </>
  )
}

function quoteTypeLabel(quote?: Quote) {
  if (quote?.method === 'api') return 'API'
  if (quote?.method === 'upload') return 'Contract'
  if (quote?.source === 'shipper') return 'Shipper'
  return 'Spot'
}

export default QuoteFormRow
