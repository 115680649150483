import {
  BaseValidation,
  email,
  integer,
  isoDate,
  maxLength,
  maxValue,
  minLength,
  minValue,
  regex,
} from 'valibot'

export type ArrayValidators = {
  maxLength?: number
  minLength?: number
}

export type NumberValidators = {
  minValue?: number
  maxValue?: number
  int?: boolean
}

export type StringValidators = {
  maxLength?: number
  minLength?: number
  email?: boolean
  regex?: RegExp
  isoDate?: boolean
  isoDateTime?: boolean
}

export const validatorMap: Record<string, (...args: any) => BaseValidation> = {
  maxLength: (value: number) =>
    maxLength(value, `Must have length less than or equal to ${value}.`),
  minLength: (value: number) =>
    minLength(value, `Must have greater than or equal to ${value}.`),
  minValue: (value: number) =>
    minValue(value, `Must be greater than or equal to ${value}.`),
  maxValue: (value: number) =>
    maxValue(value, `Must be less than or equal to ${value}.`),
  email,
  regex: (value: RegExp, errorMessage?: string) =>
    regex(value, errorMessage || 'Invalid value provided'),
  int: (value: boolean) => (value ? integer() : undefined),
  isoDate: (value: boolean) => (value ? isoDate() : undefined),
  isoDateTime: (value: boolean) =>
    value
      ? regex(
          /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/,
        )
      : undefined,
} as const
