import PropTypes from 'prop-types'

import ActiveCard from '../active/ActiveCard'
import { OpportunityCard } from '../opportunities/OpportunityCard'
import { QuotingCard } from '../quoting/QuotingCard'
import WonCard from '../won/WonCard'

const SearchResults = ({ activeId, data, openMessages, setActiveId }) => {
  if (data.length === 0) return <p>No results found.</p>

  return data.map(opportunity => {
    const { isArchived, loadId, status } = opportunity

    let CardComponent = OpportunityCard
    if (status === 'offered' || status === 'lost') CardComponent = QuotingCard
    if (status === 'awarded') CardComponent = WonCard
    if (status === 'confirmed') CardComponent = ActiveCard

    return (
      <CardComponent
        isActive={loadId === activeId}
        isArchived={isArchived}
        key={loadId}
        onClick={() => setActiveId(loadId)}
        openMessages={openMessages}
        opportunity={opportunity}
      />
    )
  })
}

SearchResults.propTypes = {
  activeId: PropTypes.string,
  data: PropTypes.array.isRequired,
  openMessages: PropTypes.func.isRequired,
  setActiveId: PropTypes.func.isRequired,
}

export default SearchResults
