import { debug } from './debug'
import { parseQueryString } from './query-string'

export function redirectToApp(options?: { location?: { search?: string } }) {
  options = options || {}

  const { location } = options // pre-authenticated, not a new login
  let redir = '/'

  if (location?.search?.length) {
    const args = parseQueryString(location.search)
    if (args['load']) redir += `direct/${args['load']}`
  }

  debug.log(`Redirecting to: ${redir}`)

  window.location.href = redir
}

export function redirectToLogin(location?: { pathname: string }) {
  const rHasShipment = /^\/direct\/(\w+)\?{0,1}.*$/i.exec(
    location?.pathname ?? '',
  )

  let redir = '/login'

  if (rHasShipment) {
    const id = rHasShipment[1]
    redir += `?load=${id}`
  }

  window.location.href = redir
}
