import clsx from 'clsx'

import { DelimitedContent, Icon, Slash } from '@fv/client-components'
import {
  addressFuncs,
  composeStopDetails,
  displayLocShipType,
} from '@fv/client-core'
import { type ShipmentLocation } from '@fv/client-types'

import DateDisplay from './DateDisplay'
import RefNumbers from './RefNumbers'
import StopAccessorials from './StopAccessorials'

type StopListProps = {
  bolNum?: string
  locations: ShipmentLocation[]
  showAddress?: boolean
}

const StopList = ({
  bolNum,
  locations,
  showAddress = false,
}: StopListProps) => {
  return (
    <>
      {locations.map(x => {
        const refNums = x.refNums?.filter(n => n.value !== bolNum)
        const shipType = displayLocShipType(x)
        return (
          <li
            className="general-list__item general-list__date-icon multi-truck-stop-location items-start"
            key={x.sequence}
          >
            <DateDisplay
              className="shipment-item__calendar-icon"
              containerClassName="pickup-delivery-wrapper"
              type={x.type}
              value={x.stopDate}
            />
            <Icon
              className={clsx('fa-fw flex-none', {
                'text-fv-origin': x.type === 'origin',
                'text-fv-destination': x.type === 'destination',
                'text-fv-multi-stop': x.type === 'stop',
              })}
              icon="map-marker"
              transform="right-3 down-2.5"
            />

            <p className="mb-0 ml-2">
              <DelimitedContent delimiter={<Slash />}>
                <span>
                  {' '}
                  {composeStopDetails(x)} at{' '}
                  {showAddress &&
                    addressFuncs.companyAddress(x, {
                      includeLine2: true,
                      dependentOnCompany: false,
                      includeCityStateZip: false,
                    }) + ', '}
                  {addressFuncs.cityStateZip(x)}
                </span>
                {shipType && <span>{shipType}</span>}
                {!!x.accessorials?.length && (
                  <StopAccessorials accessorials={x.accessorials} />
                )}
                {!!refNums?.length && <RefNumbers refNums={refNums} />}
              </DelimitedContent>
            </p>
          </li>
        )
      })}
    </>
  )
}

export default StopList
