import { Input } from 'valibot'

import {
  vBoolean,
  vCoerce,
  vCurrency,
  vNumber,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vString,
  vTransform,
} from '../validation'
import { freightClasses, hazardClasses, workflows } from './common'
import { PackageTypeMapper, packagingTypes } from './packaging-type'
import {
  legacyPackingGroups,
  packingGroups,
  parseLegacyPackingGroup,
} from './packing-group'

const readOnlyDetailSchema = vObject({
  declaredValue: vOptional(vBoolean()),
  description: vOptional(vBoolean()),
  freightClass: vOptional(vBoolean()),
  hazardClass: vOptional(vBoolean()),
  hazardous: vOptional(vBoolean()),
  hazmatId: vOptional(vBoolean()),
  height: vOptional(vBoolean()),
  length: vOptional(vBoolean()),
  nmfc: vOptional(vBoolean()),
  nonStandard: vOptional(vBoolean()),
  package: vOptional(vBoolean()),
  packingGroup: vOptional(vBoolean()),
  pieces: vOptional(vBoolean()),
  saidToContain: vOptional(vBoolean()),
  saidToContainPackagingType: vOptional(vBoolean()),
  secondaryHazardClass: vCoerce(vBoolean(), v => !!v),
  specialPermit: vCoerce(vBoolean(), v => !!v),
  stackable: vOptional(vBoolean()),
  tertiaryHazardClass: vCoerce(vBoolean(), v => !!v),
  weight: vOptional(vBoolean()),
  width: vOptional(vBoolean()),
})

export const hazardDetailSchema = vObject({
  hazmatId: vOptional(vString({ regex: /^(NA|UN)\d{4}$/ })),
  specialPermit: vOptional(vString()),
  packingGroup: vOptional(
    vTransform(
      vPicklist([
        ...legacyPackingGroups,
        ...packingGroups.map(p => p.key),
        ...packingGroups.map(p => p.name),
      ]),
      value => parseLegacyPackingGroup(value),
    ),
  ),
  hazardClass: vOptional(vPicklist(hazardClasses.map(c => c.key))),
  secondaryHazardClass: vOptional(
    vPicklist(hazardClasses.filter(c => c.isValidSubClass).map(c => c.key)),
  ),
  tertiaryHazardClass: vOptional(
    vPicklist(hazardClasses.filter(c => c.isValidSubClass).map(c => c.key)),
  ),
})

export type ReadOnlyDetail = vOutput<typeof readOnlyDetailSchema>

export const handlingUnitSchema = vObject({
  declaredValue: vOptional(
    vObject({
      amount: vOptional(vNumber()),
      currency: vCurrency('usd'),
    }),
  ),
  description: vOptional(vString()),
  freightClass: vOptional(vPicklist(freightClasses)),
  nmfc: vOptional(vString({ regex: /^[0-9-]+$/ })),
  mode: vCoerce(vPicklist(workflows), value => value || 'ltl'),
  // TODO: use valibot check to enforce "all or none" dims policy after https://github.com/freightview/fv/pull/192
  height: vCoerce(vOptional(vNumber({ int: true, minValue: 1 })), dim =>
    dim === 0 ? undefined : dim,
  ),
  // TODO: use valibot check to enforce "all or none" dims policy after https://github.com/freightview/fv/pull/192
  length: vCoerce(vOptional(vNumber({ int: true, minValue: 1 })), dim =>
    dim === 0 ? undefined : dim,
  ),
  weight: vOptional(vNumber({ int: true })),
  // TODO: use valibot check to enforce "all or none" dims policy after https://github.com/freightview/fv/pull/192
  width: vCoerce(vOptional(vNumber({ int: true, minValue: 1 })), dim =>
    dim === 0 ? undefined : dim,
  ),
  pieces: vOptional(vNumber({ int: true })),
  saidToContain: vOptional(vNumber({ int: true })),
  package: vTransform(
    vOptional(vPicklist(packagingTypes.map(p => p.key))),
    value => (value ? PackageTypeMapper.coreToLegacy(value) : value),
  ),
  saidToContainPackagingType: vTransform(
    vOptional(
      vPicklist(packagingTypes.filter(p => p.canBeContained).map(p => p.key)),
    ),
    value => (value ? PackageTypeMapper.coreToLegacy(value) : value),
  ),
  hazardous: vOptional(vCoerce(vBoolean(), value => !!value)),
  hazard: vOptional(hazardDetailSchema),
  stackable: vOptional(vCoerce(vBoolean(), value => !!value)),
  nonStandard: vOptional(vCoerce(vBoolean(), value => !!value)),
  readOnly: vOptional(readOnlyDetailSchema),
  autoGenerated: vOptional(vCoerce(vBoolean(), v => !!v)),
})

export type HandlingUnitPropsInput = Input<typeof handlingUnitSchema>
export type HandlingUnitProps = vOutput<typeof handlingUnitSchema>
