import {
  type ChangeEvent,
  type ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
} from 'react'

import { useIgnoreWheelEvents } from './hooks'
import { type InputProps } from './types'

const invalidPatternMessage = 'Max of two decimal places.'
const priceRegEx = /^\d*\.?\d{0,2}$/

export const PriceInput = forwardRef(
  (
    { error, name, onChange, ...rest }: InputProps,
    ref?: ForwardedRef<HTMLInputElement | null>,
  ) => {
    const localRef = useRef<HTMLInputElement | null>(null)
    useIgnoreWheelEvents(localRef)

    useEffect(() => {
      if (!error) return localRef.current?.setCustomValidity('')
      localRef.current?.setCustomValidity(error)
      localRef.current?.reportValidity()
    }, [error])

    // Trim space and check validity
    function localOnChange(e: ChangeEvent<HTMLInputElement>) {
      e.target.value = e.target.value.trim()
      if (priceRegEx.test(e.target.value)) e.target.setCustomValidity('')
      else e.target.setCustomValidity(invalidPatternMessage)
      onChange && onChange(e)
    }

    function setRef(element: HTMLInputElement | null) {
      localRef.current = element
      if (!ref) return
      if (typeof ref === 'function') ref(element)
      else ref.current = element
    }

    return (
      <input
        autoComplete="off"
        data-lpignore
        id={name}
        min={0}
        name={name}
        onChange={localOnChange}
        ref={setRef}
        step={0.01}
        type="number"
        {...rest}
      />
    )
  },
)
