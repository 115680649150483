import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useCacheHelpers } from '../opportunities'
import { bucketCountKeys } from '../opportunities/useBucketCounts'
import { getStartDateForStatus } from '../opportunities/useOpportunities'

async function archiveOpportunity({ archive, loadId }) {
  const endpoint = `${apiUri}/loads/${loadId}/archive`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ archive }),
    method: 'PUT',
  })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json // updatedOpportunity
  throw response.errorMessage
}

// Used for both archive/unarchive via the `archive` flag
export function useArchiveOpportunity(status) {
  const { removeFromList, updateCachedOpportunity } = useCacheHelpers()
  const queryClient = useQueryClient()

  return useMutation(archiveOpportunity, {
    onSuccess: opportunity => {
      const { isArchived, loadId } = opportunity
      const message = `Shipment ${
        isArchived ? 'archived' : 'unarchived'
      } successfully.`

      updateCachedOpportunity({ opportunity })
      removeFromList({
        loadId,
        queryKey: ['opportunities', isArchived ? status : 'archived'],
      })

      toast.success(message)

      setTimeout(() => {
        queryClient.invalidateQueries([
          'opportunities',
          isArchived ? 'archived' : 'confirmed',
        ])

        queryClient.invalidateQueries(
          bucketCountKeys.byStartDate(getStartDateForStatus(status)),
        )
      }, 1000)
    },
  })
}
