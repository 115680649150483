import { Icon } from '@fv/client-components'

const LoadingPage = () => {
  return (
    <div className="grid-c-portal">
      <main>
        <h1>
          <Icon icon="spinner" />
        </h1>
      </main>
    </div>
  )
}

export default LoadingPage
