import clsx from 'clsx'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

import { FvButton, Tooltip } from '@fv/client-components'

import { supportMessage } from '../../constants'
import { useArchiveOpportunity, useRejectLoad } from '../../hooks/loads'
import ActionsBanner from '../shared/ActionsBanner'
import ShipmentDetails from '../shared/ShipmentDetails'
import ShipperCanceledOverlay from '../ShipperCanceledOverlay'

const ActiveCard = ({
  isActive,
  isArchived,
  onClick,
  openMessages,
  opportunity,
}) => {
  const { isCanceled, isLegacy, loadId, status } = opportunity
  const archive = useArchiveOpportunity(status)
  const archiveIcon = isArchived ? 'trash-restore-alt' : 'archive'
  const rejectLoad = useRejectLoad()

  function archiveQuoteRequest(shouldArchive) {
    if (archive.isLoading || rejectLoad.isLoading) return

    archive.mutateAsync({ archive: shouldArchive, loadId }).catch(() => {
      toast.error(`Unable to archive shipment, ${supportMessage}`)
    })
  }

  function rejectActiveLoad() {
    if (archive.isLoading || rejectLoad.isLoading) return

    if (
      window.confirm(
        "By clicking 'ok' you are acknowledging that you can no longer take this load even though you have already confirmed with the shipper. We will notify the shipper in Freightview and via email.",
      )
    ) {
      rejectLoad.mutateAsync({ loadId }).catch(() => {
        toast.error(`Unable to decline load, ${supportMessage}`)
      })
    }
  }

  return (
    <article
      className={clsx('shipment-item pb-10', {
        'shipment-item--selected': isActive,
      })}
      onClick={onClick}
    >
      <div className="shipment-item__initial">
        <ActionsBanner openMessages={openMessages} opportunity={opportunity} />
        <ShipmentDetails opportunity={opportunity} />
      </div>
      <div
        className={`absolute bottom-0 flex ${
          isActive ? 'right-12' : 'right-4'
        }`}
      >
        <Tooltip label={isArchived ? 'Move back to active queue' : 'Archive'}>
          <FvButton
            onClick={() => archiveQuoteRequest(!isArchived)}
            theme="plain"
            icon={archive.isLoading ? 'spinner' : archiveIcon}
          />
        </Tooltip>

        {isLegacy === false && (
          <Tooltip label="Decline this load">
            <FvButton
              className="m-0"
              icon={rejectLoad.isLoading ? 'spinner' : 'ban'}
              onClick={rejectActiveLoad}
              theme="plain"
            />
          </Tooltip>
        )}
      </div>

      {isCanceled && (
        <ShipperCanceledOverlay
          canArchive={!isArchived}
          opportunity={opportunity}
        />
      )}
    </article>
  )
}

ActiveCard.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isArchived: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  openMessages: PropTypes.func.isRequired,
  opportunity: PropTypes.shape({
    isCanceled: PropTypes.bool,
    loadId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default ActiveCard
