import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

import {
  FvButton,
  FvLinkButton,
  Icon,
  TextField,
  ValidatedForm,
} from '@fv/client-components'

import { supportMessage } from '../../constants'
import { useEditConfirmationNumber } from '../../hooks/loads'
import { type Opportunity } from '../../types/Opportunity'
import RefNumbers from '../shared/RefNumbers'
import { LoadDetails } from './LoadDetails'
import StopList from './StopList'

const confNumInputName = 'confirmationNumber'
type ShipmentDetailsProps = {
  opportunity: Opportunity
}

const ShipmentDetails = ({ opportunity }: ShipmentDetailsProps) => {
  const [isEditingConfNum, setEditingConfNum] = useState(false)
  const editConfirmation = useEditConfirmationNumber()
  const location = useLocation()

  const {
    bol,
    isArchived,
    isMultiTruck,
    loadId,
    locations,
    pickup,
    quoteRequestId,
    refNums,
    shipper,
    status,
  } = opportunity

  const canEditConfNum = status === 'confirmed' && !isArchived
  const confNum = pickup?.confirmationNumber ?? ''
  const isMultiStop = locations.length > 2
  const isViewingRelatedOpps = location.pathname.startsWith('/direct/')
  const refNumbers = refNums?.filter(n => n.value !== bol?.bolNumber)
  const showConfNum = Boolean(confNum) || canEditConfNum

  const onValidSubmit = (form: HTMLFormElement) => {
    if (editConfirmation.isLoading) return

    const nextConfNum =
      // @ts-expect-error valid use case
      form.elements[confNumInputName].value.trim()

    if (nextConfNum === confNum) {
      setEditingConfNum(false)
      return
    }

    editConfirmation
      .mutateAsync({
        confirmationNumber: nextConfNum,
        loadId,
      })
      .then(() => {
        setEditingConfNum(false)
      })
      .catch(() => {
        toast.error(`Unable to update confirmation number, ${supportMessage}`)
      })
  }

  let fromLabel = 'From'
  if (isMultiStop) fromLabel = 'Multi stop shipment from'
  if (isMultiTruck) fromLabel = 'Multi truck shipment from'

  return (
    <div
      className={clsx('shipment-item__information', {
        'shipment-item__information--multi-stop': isMultiStop,
      })}
    >
      <ul className="general-list general-list-fc-no-pad general-list-lc-no-border">
        <li className="general-list__item flex-wrap">
          {(isMultiStop || isMultiTruck) && (
            <Icon
              icon={isMultiStop ? 'route-interstate' : 'layer-group'}
              className="fa-fw"
            />
          )}
          <span>
            {bol?.bolNumber && `BOL# ${bol.bolNumber} / `}
            {showConfNum && 'Confirmation# '}
            {showConfNum && canEditConfNum ? (
              <FvButton
                theme="underlined"
                onClick={() => setEditingConfNum(p => !p)}
              >
                {confNum || 'NA'}
              </FvButton>
            ) : (
              confNum
            )}
            {showConfNum && ' / '}
            {fromLabel}: {shipper.companyName}
            {status !== 'lost' && (
              <>
                {' '}
                <RefNumbers refNums={refNumbers} />
              </>
            )}
          </span>

          {isMultiTruck && !isViewingRelatedOpps && (
            <div
              style={{
                flex: '1 1 auto',
                textAlign: 'right',
              }}
            >
              <FvLinkButton
                theme="underlined"
                className="standard-link"
                to={`/direct/${quoteRequestId}`}
              >
                View related opportunities
              </FvLinkButton>
            </div>
          )}

          {isEditingConfNum && (
            <div className="edit-confirmation-ctn">
              <ValidatedForm
                className="input-group input-group--flex"
                onValidSubmit={onValidSubmit}
              >
                <TextField
                  autoFocus
                  className="form-control"
                  defaultValue={confNum}
                  name={confNumInputName}
                  readOnly={editConfirmation.isLoading}
                  required
                  type="text"
                />
                <div className="input-group__append input-group--flex">
                  <FvButton
                    loading={editConfirmation.isLoading}
                    icon="check"
                    theme="default"
                    type="submit"
                  >
                    Update confirmation number
                  </FvButton>
                  <FvButton
                    icon="times"
                    onClick={() => setEditingConfNum(false)}
                    theme="plain"
                    className="pr-0"
                  />
                </div>
              </ValidatedForm>
            </div>
          )}
        </li>

        <StopList
          bolNum={bol?.bolNumber}
          locations={locations}
          showAddress={status === 'awarded'}
        />

        <LoadDetails load={opportunity} />
      </ul>
    </div>
  )
}

export default ShipmentDetails
