import { useRef, useState } from 'react'
import toast from 'react-hot-toast'

import {
  CheckboxField,
  FvButton,
  TextField,
  ValidatedForm,
} from '@fv/client-components'

import { supportMessage } from '../constants'
import { useShareOpportunity } from '../hooks/loads'

const defaults = { email: '', all: false }

type ShareProps = {
  loadId: string
  onClose: () => void
}

const Share = ({ loadId, onClose }: ShareProps) => {
  const [fields, setFields] = useState(defaults)
  const refForm = useRef<HTMLFormElement>(null)
  const share = useShareOpportunity()

  function onCloseLocal() {
    setFields(defaults)
    refForm.current?.reset()
    onClose()
  }

  function onFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.target.name
    const value = e.target.value
    setFields(f => ({ ...f, [name]: value }))
  }

  async function onValidSubmit() {
    share
      .mutateAsync({ email: fields.email, loadId, all: fields.all })
      .then(onCloseLocal)
      .catch(e => {
        toast.error(
          e?.message || `Unable to share opportunity, ${supportMessage}`,
        )
      })
  }

  return (
    <ValidatedForm
      className="share-form"
      onValidSubmit={onValidSubmit}
      ref={refForm}
    >
      <label htmlFor="email" className="label">
        Share this via email to:
      </label>
      <TextField
        autoFocus
        className="form-control mb-6"
        disabled={share.isLoading}
        id="email"
        maxLength={200}
        name="email"
        onChange={onFieldChange}
        placeholder="e.g. joe@example.com"
        required
        type="email"
        value={fields.email}
      />
      <div className="mb-6">
        <CheckboxField
          name="all"
          label="Share all my shipments"
          onChange={e => setFields(f => ({ ...f, all: e.target.checked }))}
        />
      </div>
      <div className="flex">
        <FvButton
          type="button"
          icon="times"
          theme="default"
          onClick={onCloseLocal}
        >
          <span>Cancel</span>
        </FvButton>
        <FvButton
          type="submit"
          theme="primary"
          disabled={share.isLoading}
          fwd
          icon={share.isLoading ? 'spinner' : 'paper-plane'}
        >
          <span>Send email link</span>
        </FvButton>
      </div>
    </ValidatedForm>
  )
}

export default Share
