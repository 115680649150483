import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

import { FvButton, Icon } from '@fv/client-components'

import { supportMessage } from '../constants'
import {
  useArchiveCanceledOpportunities,
  useArchiveOpportunity,
} from '../hooks/loads'

const ShipperCanceledOverlay = ({ canArchive = true, opportunity }) => {
  const { carrierId, loadId, status } = opportunity
  const archive = useArchiveOpportunity(status)
  const archiveAll = useArchiveCanceledOpportunities()
  const isLoading = archive.isLoading || archiveAll.isLoading

  function removeCanceledShipments(e) {
    e.preventDefault()
    if (isLoading) return

    if (
      window.confirm(
        'Remove all canceled shipments inside of Freightview Carrier?',
      )
    ) {
      archiveAll.mutateAsync({ carrierId }).catch(() => {
        toast.error(`Unable to remove canceled shipments, ${supportMessage}`)
      })
    }
  }

  function removeShipment(e) {
    e.preventDefault()
    if (isLoading) return

    archive.mutateAsync({ archive: true, loadId }).catch(() => {
      toast.error(`Unable to remove shipment, ${supportMessage}`)
    })
  }

  return (
    <div className="overlay overlay--canceled">
      <div className="overlay--canceled-info">
        <Icon icon="ban" className="color-warning" />
        <span>Canceled by shipper</span>

        {canArchive && <hr />}

        {canArchive && (
          <ul className="general-list-flex">
            <li className="general-list-flex__item">
              <FvButton
                onClick={removeShipment}
                icon={archive.isLoading ? 'spinner' : 'trash'}
              >
                <span>Remove</span>
              </FvButton>
            </li>
            <li className="general-list-flex__item">
              <FvButton
                className="standard-link"
                onClick={removeCanceledShipments}
                icon={archiveAll.isLoading ? 'spinner' : 'trash'}
              >
                <span>Remove all</span>
              </FvButton>
            </li>
          </ul>
        )}
      </div>
    </div>
  )
}

ShipperCanceledOverlay.propTypes = {
  canArchive: PropTypes.bool,
  opportunity: PropTypes.shape({
    carrierId: PropTypes.string.isRequired,
    loadId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default ShipperCanceledOverlay
