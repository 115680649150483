import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useUserContext } from './'

async function fetchCarrierContext(carrierId) {
  const endpoint = `${apiUri}/carriers/${carrierId}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useCarrierContext() {
  const context = useUserContext()
  const carrierId = context.data?.user?.carrierId

  return useQuery(
    ['carrierContext', carrierId],
    () => fetchCarrierContext(carrierId),
    {
      enabled: Boolean(carrierId),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  )
}
