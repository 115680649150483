import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { type PublicShipment } from './types'

async function getPublicShipment(
  loadId: string,
  token: string | null,
): Promise<PublicShipment> {
  if (!token) throw new Error('No authentication token provided.')

  const endpoint = new URL(`${apiUri}/loads/${loadId}/public`)
  endpoint.searchParams.set('token', token)

  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint.toString(), options)

  if (response.ok) return response.json
  throw mapError(response.errorMessage)
}

export function usePublicShipment(loadId: string, token: string | null) {
  return useQuery(
    ['public', loadId, token],
    () => getPublicShipment(loadId, token),
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: (e: { message: string }) => {},
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    },
  )
}

function mapError(errorRes: { message: string }) {
  const error = { ...errorRes }
  const message = errorRes?.message ?? ''

  if (/bad request on parameter "request.path.loadId"/i.test(message)) {
    error.message = 'Invalid shipment ID in URL.'
  } else {
    error.message = message || 'Sorry, something went wrong.'
  }

  return error
}
