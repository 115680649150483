import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'

async function updateProfile({
  carrierName,
  profile, // { firstName, lastName, role }
}) {
  const endpoint = `${apiUri}/users/profile`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      carrierName,
      profile,
    }),
    method: 'PUT',
  })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUpdateProfile() {
  const queryClient = useQueryClient()

  return useMutation(updateProfile, {
    onSuccess: ({ carrierId, carrierName, profile }) => {
      queryClient.setQueryData(['carrierContext', carrierId], (prev = {}) => ({
        ...prev,
        name: carrierName,
      }))

      queryClient.setQueryData(['userContext'], (prev = {}) => ({
        ...prev,
        user: {
          ...prev?.user,
          profile,
        },
      }))

      toast.success('Updated profile successfully.')
    },
  })
}
