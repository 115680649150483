import { type PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

import { Modal } from '@fv/client-components'

import { ModalCloseButton } from './ModalCloseButton'

type Props = PropsWithChildren & {
  onClose?: () => void
}
export const CarrierModal = ({ children, onClose }: Props) => {
  return createPortal(
    <Modal>
      <ModalCloseButton onClick={onClose} />
      {children}
    </Modal>,
    document.getElementById('root') as HTMLElement,
  )
}
