import pluralize from 'pluralize'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton, Icon, ValidatedForm } from '@fv/client-components'
import { getEquipmentLabel } from '@fv/client-core'

import { supportMessage } from '../../constants'
import { useSendMessage } from '../../hooks/messages'
import { useQuotes, useRetractQuote, useSubmitQuotes } from '../../hooks/quotes'
import { useAppSettings, useTypeNames } from '../../hooks/settings'
import { type Opportunity } from '../../types/Opportunity'
import { TextAreaInput } from '../inputs/TextAreaInput'
import QuoteFormRow from './QuoteFormRow'

type Props = {
  hasQuoted?: boolean
  opportunity: Opportunity
  isExpired?: boolean
}

const QuoteForm = ({ hasQuoted = false, opportunity, isExpired }: Props) => {
  const [isEditingContractedRate, setEditingContractedRate] = useState(false)
  const [selectedQuotes, setSelectedQuotes] = useState<string[]>([])
  const { equipmentRequested, loadId, quoteRequestId, status, targetPrice } =
    opportunity
  const { alternateTypes, type } = equipmentRequested
  const formRef = useRef<HTMLFormElement>(null)

  const quotesQuery = useQuotes(loadId)
  const quotes = quotesQuery.data ?? []
  const apiRate = quotes.find(q => q.method === 'api')
  const contractedRate = quotes.find(q => q.method === 'upload')
  const carrierRate = quotes.find(
    q => q.method === 'manual' && q.source === 'carrier',
  )

  useEffect(() => {
    const quotes = quotesQuery.data ?? []
    setSelectedQuotes(quotes.map(q => q._id))
  }, [quotesQuery.data])

  const { sendMessage, isSendingMessage } = useSendMessage(loadId)
  const retractQuote = useRetractQuote(quoteRequestId)
  const submitQuotes = useSubmitQuotes(status, quoteRequestId)
  const isBusy =
    retractQuote.isLoading || submitQuotes.isLoading || isSendingMessage

  const settingsQuery = useAppSettings()
  const { allowAPIQuoteEdit } = settingsQuery.data ?? {}
  const canEdit =
    !!carrierRate ||
    (!!apiRate && !!allowAPIQuoteEdit) ||
    (!!contractedRate && isEditingContractedRate)

  const { equipmentName } = useTypeNames()
  const equipmentLabel = getEquipmentLabel({
    altEquipmentNames: alternateTypes?.map(equipmentName),
    equipmentName: equipmentName(type),
  })

  const equipmentTypeOptions = [
    {
      value: type,
      text: equipmentName(type),
    },
  ]

  if (alternateTypes?.length) {
    alternateTypes.forEach(altType => {
      if (!altType) return

      equipmentTypeOptions.push({
        text: equipmentName(altType),
        value: altType,
      })
    })
  }

  function getInputEl(name: string): HTMLInputElement {
    // @ts-expect-error valid use case
    return formRef.current?.elements[name]
  }

  function onValidSubmit() {
    if (isBusy) return

    const messageEl = getInputEl('message')
    const messageText = messageEl?.value.trim() ?? ''

    if (!hasQuoted) {
      const amount = getInputEl('amount')?.valueAsNumber
      const equipmentType = getInputEl('equipmentType')?.value
      const quoteNum = getInputEl('quoteNumber')?.value

      return submitQuotes
        .mutateAsync({
          loadId,
          messageText,
          quotes: [
            {
              amount,
              currency: 'usd', // TODO
              equipmentType: equipmentType ?? type,
              quoteNum,
            },
          ],
        })
        .then(() => {
          if (messageEl) messageEl.value = ''
        })
        .catch(() => {
          toast.error(`Unable to add quote, ${supportMessage}`)
        })
    }

    if (!canEdit) {
      if (!messageText) return
      return sendMessage({
        messageText,
        onSuccess: () => {
          if (messageEl) messageEl.value = ''
        },
      })
    }

    const quoteToRetract = quotes.find(q => !selectedQuotes.includes(q._id))

    if (quoteToRetract) {
      retractQuote
        .mutateAsync({
          loadId,
          quoteId: quoteToRetract._id,
        })
        .catch(() => {
          toast.error(`Unable to retract quote, ${supportMessage}`)
        })
    }

    const quoteDTOs = selectedQuotes.map(id => {
      const amount = getInputEl(`amount_${id}`)?.valueAsNumber
      const equipmentType = getInputEl(`equipmentType_${id}`)?.value
      const quoteNum = getInputEl(`quoteNumber_${id}`)?.value

      return {
        amount,
        currency: 'usd', // TODO
        equipmentType: equipmentType ?? type,
        quoteId: id,
        quoteNum,
      }
    })

    const quotesToUpdate = quoteDTOs.filter(dto => {
      const original = quotes.find(q => q._id === dto.quoteId)

      return (
        dto.amount !== original?.amount ||
        dto.equipmentType !== original?.equipmentType ||
        dto.quoteNum !== (original?.quoteNum ?? '')
      )
    })

    if (!quotesToUpdate.length && !quoteToRetract) {
      return toast.error('Must make a change to update bids.')
    }

    const apiRateUpdate = quotesToUpdate.find(q => q.quoteId === apiRate?._id)

    // Ensure quote number and amount were changed for API rates
    // https://github.com/freightview/freightview/issues/20682
    if (apiRateUpdate) {
      const id = apiRateUpdate.quoteId

      if (apiRateUpdate.quoteNum === (apiRate?.quoteNum ?? '')) {
        const quoteNumberEl = getInputEl(`quoteNumber_${id}`)
        quoteNumberEl?.setCustomValidity('Quote number must be changed.')
        return formRef.current?.reportValidity()
      }

      if (apiRateUpdate.amount === apiRate?.amount) {
        const amountEl = getInputEl(`amount_${id}`)
        amountEl?.setCustomValidity('Amount must be changed.')
        return formRef.current?.reportValidity()
      }
    }

    if (quotesToUpdate.length) {
      submitQuotes
        .mutateAsync({
          isUpdate: true,
          loadId,
          messageText,
          quotes: quotesToUpdate,
        })
        .then(() => {
          setEditingContractedRate(false)
          if (messageEl) messageEl.value = ''
        })
        .catch(() => {
          toast.error(
            `Unable to update ${pluralize(
              'quote',
              quotesToUpdate.length,
            )}, ${supportMessage}`,
          )
        })
    }
  }

  function quoteSelector(quoteId: string) {
    return (isSelected: boolean) => {
      setSelectedQuotes(prev => {
        const next = prev.filter(id => id !== quoteId)
        return isSelected ? next.concat(quoteId) : next
      })
    }
  }

  return (
    <ValidatedForm
      onValidSubmit={onValidSubmit}
      ref={formRef}
      className="form-currently-quoting"
    >
      <div className="shipment-item-ancillary">
        <div className="flex items-start border-b border-dashed border-slate-300 pb-2">
          <Icon icon="dollar-sign" className="color-dark" />
          <p className="-mt-[.15rem] ml-2">
            You are quoting a {equipmentLabel}{' '}
            {targetPrice && (
              <>
                / Shipper target price is{' '}
                <span className="font-bold">${targetPrice}</span>
              </>
            )}
          </p>
        </div>

        <div className="mb-3">
          {hasQuoted &&
            quotes.map(q => (
              <QuoteFormRow
                defaultEquipmentType={type}
                equipmentTypeOptions={equipmentTypeOptions}
                getInputEl={getInputEl}
                isBusy={isBusy || isExpired}
                isEditingContractedRate={isEditingContractedRate}
                isSelected={selectedQuotes.includes(q._id)}
                key={q._id}
                quote={q}
                quoteSettings={settingsQuery.data}
                setEditingContractedRate={setEditingContractedRate}
                setSelected={quoteSelector(q._id)}
              />
            ))}

          {hasQuoted && quotesQuery.isError && !quotes.length && (
            <div className="box box--help-gradient mt-4">
              <div className="icon-message">
                <Icon className="color-secondary" icon="exclamation-triangle" />
                <p className="mb-0">Unable to load quotes, {supportMessage}.</p>
              </div>
            </div>
          )}

          {!hasQuoted && (
            <QuoteFormRow
              defaultEquipmentType={type}
              equipmentTypeOptions={equipmentTypeOptions}
              getInputEl={getInputEl}
              isBusy={isBusy || isExpired}
              isSelected={selectedQuotes.includes('newQuote')}
              quoteSettings={settingsQuery.data}
              setSelected={quoteSelector('newQuote')}
            />
          )}
        </div>

        <div className="form-group">
          <TextAreaInput
            className="form-control"
            disabled={isBusy || isExpired}
            maxLength={500}
            name="message"
            placeholder="Include an optional message here."
          />
        </div>

        <div className="flex items-center justify-end">
          <FvButton
            disabled={isBusy || isExpired}
            type="submit"
            theme="default"
            icon={isBusy ? 'spinner' : 'paper-plane'}
          >
            <span>
              {!hasQuoted && 'Send bid'}
              {hasQuoted &&
                (canEdit
                  ? `Update ${pluralize('bid', quotes.length)}`
                  : 'Send message')}
            </span>
          </FvButton>
        </div>
      </div>
    </ValidatedForm>
  )
}

export default QuoteForm
