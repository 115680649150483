import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  flattenPages,
  getNextPageParam,
} from '@fv/client-core'

import { apiUri, defaultPageSize } from '../../constants'
import { type Opportunity } from '../../types/Opportunity'
import { useAppFocus } from '../useAppFocus'

const optsSortTypes = ['pickupDate', 'bidExpirationDate'] as const
export type OptSortType = (typeof optsSortTypes)[number]

export type OpportunityStatus =
  | 'awarded'
  | 'confirmed'
  | 'declined'
  | 'lost'
  | 'offered'
  | 'open'

type FetchOpportunitiesParams = {
  isArchived: boolean
  limit?: number
  skip: number
  startDate: string
  status: OpportunityStatus
  sortBy: OptSortType
  sortDirection: 1 | -1
}

async function fetchOpportunities({
  isArchived = false,
  limit = defaultPageSize,
  skip = 0,
  startDate,
  status,
  sortBy,
  sortDirection,
}: FetchOpportunitiesParams): Promise<{
  data: Opportunity[]
  hasMore?: boolean
}> {
  const endpoint = `${apiUri}/opportunities`
  const options = buildFetchOptionsWithAuth({
    query: {
      isArchived,
      limit,
      skip,
      startDate,
      status,
      sortBy,
      sortDirection,
    },
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function getStartDateForStatus(status: OpportunityStatus) {
  const daysAgo = status === 'awarded' || status === 'confirmed' ? 60 : 30
  return dayjs().subtract(daysAgo, 'day').format('YYYY-MM-DD')
}

export function useOpportunities(
  listName: OpportunityStatus | 'archived',
  sortBy?: OptSortType,
  sortDirection?: 1 | -1,
) {
  const isFocused = useAppFocus()
  const isArchived = listName === 'archived'
  const listQuery = useInfiniteQuery(
    ['opportunities', listName],
    ({ pageParam = 0 }) => {
      const status = isArchived ? 'confirmed' : listName

      return fetchOpportunities({
        isArchived,
        skip: pageParam,
        startDate: getStartDateForStatus(status),
        status,
        sortBy,
        sortDirection,
      })
    },
    {
      enabled: isFocused && Boolean(listName),
      getNextPageParam,
    },
  )

  return {
    ...listQuery,
    data: flattenPages(listQuery.data?.pages),
  }
}
