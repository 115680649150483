import {
  type ChangeEvent,
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  useEffect,
  useRef,
} from 'react'

type Props = {
  error?: string
  name: string
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  options: Array<{
    text: string
    value: string
  }>
} & HTMLProps<HTMLSelectElement>

export const SelectInput = forwardRef(
  (
    { error, name, onChange, options, ...rest }: Props,
    ref?: ForwardedRef<HTMLSelectElement | null>,
  ) => {
    const localRef = useRef<HTMLSelectElement | null>(null)

    useEffect(() => {
      if (!error) return localRef.current?.setCustomValidity('')
      localRef.current?.setCustomValidity(error)
      localRef.current?.reportValidity()
    }, [error])

    function localOnChange(e: ChangeEvent<HTMLSelectElement>) {
      e.target.setCustomValidity('')
      onChange && onChange(e)
    }

    function setRef(element: HTMLSelectElement | null) {
      localRef.current = element
      if (!ref) return
      if (typeof ref === 'function') ref(element)
      else ref.current = element
    }

    return (
      <select
        id={name}
        name={name}
        onChange={localOnChange}
        ref={setRef}
        {...rest}
      >
        {options.map(o => (
          <option key={o.value} value={o.value}>
            {o.text}
          </option>
        ))}
      </select>
    )
  },
)
