import { useInfiniteQuery } from '@tanstack/react-query'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  getNextPageParam,
} from '@fv/client-core'

import { apiUri, defaultPageSize } from '../../constants'
import { type Opportunity } from '../../types/Opportunity'

async function searchOpportunities({
  searchText,
  skip,
}: {
  searchText: string
  skip: number
}): Promise<{
  data: Opportunity[]
  hasMore?: boolean
}> {
  const endpoint = `${apiUri}/opportunities/search`
  const options = buildFetchOptionsWithAuth({
    query: {
      limit: defaultPageSize,
      skip,
      searchText,
    },
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useOpportunitySearch(searchText: string) {
  return useInfiniteQuery(
    ['opportunities', 'searchResults', searchText],
    ({ pageParam = 0 }) => searchOpportunities({ searchText, skip: pageParam }),
    {
      enabled: Boolean(searchText),
      getNextPageParam,
    },
  )
}
