import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

import { setDataByPage } from '@fv/client-core'

import { type Opportunity } from '../../types/Opportunity'
import { type OpportunityStatus } from './useOpportunities'

type PagedOpportunities = {
  data: Opportunity[]
  hasMore?: boolean
}

type InfiniteOpportunityQueryData = {
  pageParams: Array<number | null>
  pages: PagedOpportunities[]
}

export function useCacheHelpers() {
  const queryClient = useQueryClient()

  return useMemo(() => {
    return {
      removeFromList,
      updateCachedOpportunity,
    }

    function removeFromList(opts: { loadId: string; queryKey: string[] }) {
      const { loadId, queryKey } = opts

      queryClient.setQueriesData<InfiniteOpportunityQueryData>(
        queryKey,
        setDataByPage(opps => opps.filter(o => o.loadId !== loadId)),
      )
    }

    function updateCachedOpportunity(opts: {
      listKeys?: Array<OpportunityStatus | 'archived' | 'searchResults'>
      opportunity: Partial<Opportunity>
    }) {
      const opportunity = opts.opportunity
      let listKeys = opts.listKeys

      listKeys = Array.isArray(listKeys)
        ? listKeys.concat('searchResults')
        : ['searchResults']

      listKeys.forEach(key => {
        queryClient.setQueriesData<InfiniteOpportunityQueryData>(
          ['opportunities', key],
          setDataByPage(opps =>
            opps.map(o => {
              if (o.loadId !== opportunity.loadId) return o
              return { ...o, ...opportunity }
            }),
          ),
        )
      })

      queryClient.setQueriesData<Opportunity[] | undefined>(
        ['quoteRequest', opportunity.quoteRequestId],
        prev =>
          prev?.map(o => {
            if (o.loadId !== opportunity.loadId) return o
            return { ...o, ...opportunity }
          }),
      )
    }
  }, [queryClient])
}
