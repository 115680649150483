import logo from '@fv/client-assets/freightview.svg'

const Branding = () => (
  <div className="branding">
    <img alt="Freightview" src={logo} />
    <span>Freightview Carrier</span>
  </div>
)

export default Branding
