import PropTypes from 'prop-types'

import { FvButton, Icon, TextField, ValidatedForm } from '@fv/client-components'

const SearchForm = ({
  isLoading = false,
  onFieldChange,
  onValidSubmit,
  value,
}) => {
  return (
    <ValidatedForm
      className="user-information-ctn"
      onValidSubmit={onValidSubmit}
    >
      <div className="input-group input-group--flex">
        <TextField
          autoFocus
          className="form-control"
          disabled={isLoading}
          maxLength={200}
          name="search"
          onChange={onFieldChange}
          placeholder="Company name, quote #, shipper ref #, p/o #"
          required
          value={value}
        />
        <FvButton
          theme="default"
          icon={isLoading ? 'spinner' : 'search'}
          disabled={isLoading}
          className="-ml-px"
          type="submit"
        >
          <span>Search</span>
        </FvButton>
      </div>
    </ValidatedForm>
  )
}

SearchForm.propTypes = {
  isLoading: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
  onValidSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default SearchForm
