import {
  vCountryAny,
  vObject,
  vOptional,
  vPostalCode,
  vString,
} from '../validation'
import { Country, LegacyCountry } from './country'

export interface AddressBase {
  address: string
  address2?: string
  adminArea3?: string
  city: string
  state: string
  postalCode: string
  country: Country
}

export interface LegacyAddressBase extends Omit<AddressBase, 'country'> {
  country: LegacyCountry
}

export interface CompanyAddressBase extends AddressBase {
  company?: string
}

export const addressBaseSchema = vObject({
  address: vString({ minLength: 1 }),
  address2: vOptional(vString()),
  adminArea3: vOptional(vString()),
  city: vString({ minLength: 1 }),
  state: vString({ minLength: 1 }),
  postalCode: vPostalCode(),
  country: vCountryAny,
})
