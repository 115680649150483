import PropTypes from 'prop-types'

import { FvButton, Icon, TextField, ValidatedForm } from '@fv/client-components'

const VerifyForm = ({ fields, errors, onFieldChange, onSubmit }) => {
  return (
    <div>
      <ValidatedForm onValidSubmit={onSubmit}>
        <div className="form-group mb-0">
          <label className="label">
            Enter the code you were sent via email
          </label>
          <div className="input-group input-group--flex">
            <TextField
              name="token"
              type="text"
              className="form-control"
              placeholder="Enter your registration code"
              onChange={onFieldChange}
              value={fields.token}
              required
              error={errors.token}
              maxLength={100}
            />

            <FvButton type="submit" className="-ml-px" theme="default">
              <Icon icon="check" />
              <span>Submit</span>
            </FvButton>
          </div>
        </div>
      </ValidatedForm>
    </div>
  )
}

VerifyForm.propTypes = {
  errors: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default VerifyForm
