import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { type Opportunity } from '../../types/Opportunity'

const defaultData: Opportunity[] = []

export function useActiveId(data = defaultData) {
  const [activeId, setActiveId] = useState('')

  useEffect(() => {
    setActiveId(prevId => {
      const prevItem = data.find(d => d.loadId === prevId)
      if (prevItem) return prevId
      return data[0]?.loadId
    })
  }, [data])

  return useMemo<[string, Dispatch<SetStateAction<string>>]>(
    () => [activeId, setActiveId],
    [activeId],
  )
}
