import { type ShipmentLocation } from '@fv/client-types'

type Props = Pick<ShipmentLocation, 'refNums'>
const RefNumbers = ({ refNums }: Props) => {
  if (!refNums?.length) return null

  return (
    <span className="text-xs">
      Ref#: {refNums.map(n => n.value).join(' / ')}
    </span>
  )
}

export default RefNumbers
