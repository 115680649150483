import { useParams } from 'react-router-dom'

import { useMessagingControls } from '../hooks/messages'
import { useActiveId, useQuoteRequest } from '../hooks/opportunities'
import ActiveCard from './active/ActiveCard'
import MessagingPage from './messaging/MessagingPage'
import { OpportunityCard } from './opportunities/OpportunityCard'
import { QuotingCard } from './quoting/QuotingCard'
import EmptyList from './shared/EmptyList'
import WonCard from './won/WonCard'

const cardComponents = {
  awarded: WonCard,
  confirmed: ActiveCard,
  declined: OpportunityCard,
  lost: QuotingCard,
  offered: QuotingCard,
  open: OpportunityCard,
}

const DirectShipmentLoader = () => {
  const { quoteRequestId } = useParams()
  const listQuery = useQuoteRequest(quoteRequestId)
  const messagingControls = useMessagingControls()
  const [activeId, setActiveId] = useActiveId(listQuery.data)

  if (listQuery.isError) {
    return (
      <>
        <main>
          <EmptyList message="Sorry, something went wrong. Please double check your direct link or contact support if the issue persists." />
        </main>
        <MessagingPage />
      </>
    )
  }

  const opportunity = listQuery.data?.find(r => r.loadId === activeId)
  const statusCounts = {}

  listQuery.data?.forEach(opp => {
    const status = opp.isCanceled ? 'canceled' : opp.status
    statusCounts[status] = (statusCounts[status] ?? 0) + 1
  })

  const heading = Object.keys(statusCounts)
    .map(k => `${statusCounts[k]} ${k}`)
    .join(', ')
    .concat(
      ` load${listQuery.data?.length > 1 ? 's' : ''} for this opportunity.`,
    )

  return (
    <>
      <main>
        {!listQuery.isLoading && listQuery.data?.length > 0 && (
          <header className="header-divider">{heading}</header>
        )}

        {listQuery.data?.length > 0 ? (
          listQuery.data.map(opp => {
            const CardComponent = cardComponents[opp.status]
            if (!CardComponent) return null // Invalid status

            return (
              <CardComponent
                isActive={opp.loadId === activeId}
                isArchived={opp.isArchived}
                key={opp.loadId}
                onClick={() => setActiveId(opp.loadId)}
                openMessages={messagingControls.open}
                opportunity={opp}
              />
            )
          })
        ) : (
          <EmptyList
            busy={listQuery.isLoading}
            message="No loads found for this opportunity."
          />
        )}
      </main>

      <MessagingPage {...messagingControls} opportunity={opportunity} />
    </>
  )
}

export default DirectShipmentLoader
