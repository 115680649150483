import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { Icon } from '@fv/client-components'

type Props = PropsWithChildren & {
  isActive?: boolean
  page?: string
}

export const ExpirationNotice = ({ children, isActive, page }: Props) => {
  return (
    <div
      className={clsx(
        'bg-fv-beer absolute top-0 border border-t-0 border-[#ddd] px-2 py-1 text-xs',
        isActive ? 'right-[calc(2.5rem-1px)] border-r' : 'right-0 border-r-0',
        page === 'quoting' && isActive && '!right-[calc(2rem-1px)]',
      )}
    >
      <Icon icon="hand-paper" className="text-fv-orange" />
      <span>{children}</span>
    </div>
  )
}
