import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useAppFocus } from '../useAppFocus'
import { getStartDateForStatus, OpportunityStatus } from './useOpportunities'

export type BucketCounts = {
  awarded: number
  awardedErrors?: number
  confirmed: number
  confirmedErrors?: number
  offered: number
  offeredErrors?: number
  open: number
  openErrors?: number
}

export const bucketCountKeys = {
  all: ['bucket-counts'] as const,
  byStartDate: (startDate: string) =>
    [...bucketCountKeys.all, startDate] as const,
}

async function fetchBucketCounts(): Promise<BucketCounts> {
  const endpoint = `${apiUri}/opportunities/counts`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useBucketCounts() {
  const isFocused = useAppFocus()

  return useQuery(['bucket-counts'], () => fetchBucketCounts(), {
    enabled: isFocused,
  })
}
