import {
  vCoercedDate,
  vObject,
  vOptional,
  vOutput,
  vString,
} from '../validation'

export const updateBidExpirationDto = vObject({
  local: vOptional(vString()),
  utc: vOptional(vCoercedDate),
})
export type UpdateBidExpirationDto = vOutput<typeof updateBidExpirationDto>

export interface LoadBidExpiration {
  utc: Date
  local: string
}
