import {
  type ChangeEvent,
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  useEffect,
  useRef,
} from 'react'

type Props = {
  error?: string
  name: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
} & HTMLProps<HTMLTextAreaElement>

export const TextAreaInput = forwardRef(
  (
    { error, name, onChange, ...rest }: Props,
    ref?: ForwardedRef<HTMLTextAreaElement | null>,
  ) => {
    const localRef = useRef<HTMLTextAreaElement | null>(null)

    useEffect(() => {
      if (!error) return localRef.current?.setCustomValidity('')
      localRef.current?.setCustomValidity(error)
      localRef.current?.reportValidity()
    }, [error])

    function localOnChange(e: ChangeEvent<HTMLTextAreaElement>) {
      e.target.value = e.target.value.trimStart()
      e.target.setCustomValidity('')
      onChange && onChange(e)
    }

    function setRef(element: HTMLTextAreaElement | null) {
      localRef.current = element
      if (!ref) return
      if (typeof ref === 'function') ref(element)
      else ref.current = element
    }

    return (
      <textarea
        autoComplete="off"
        data-lpignore
        id={name}
        name={name}
        onChange={localOnChange}
        ref={setRef}
        {...rest}
      />
    )
  },
)
