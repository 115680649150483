import { Icon } from '@fv/client-components'

const QuotingLegend = () => (
  <div className="b800:hidden">
    <span className="legend-item">
      <Icon icon="arrow-down" className="color-success" />
      <span>Lowest bid</span>
    </span>
    <span className="legend-item">
      <Icon icon="arrow-up" className="color-warning" />
      <span>Not lowest bid</span>
    </span>
  </div>
)

export default QuotingLegend
