import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useCacheHelpers } from '../opportunities'

async function deleteDocument({ document, loadId }) {
  const endpoint = `${apiUri}/loads/${loadId}/documents/${document._id}`
  const options = buildFetchOptionsWithAuth({ method: 'DELETE' })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json // updatedOpportunity
  throw response.errorMessage
}

export function useDeleteDocument() {
  const { updateCachedOpportunity } = useCacheHelpers()

  return useMutation(deleteDocument, {
    onSuccess: opportunity => {
      updateCachedOpportunity({
        listKeys: ['archived', 'confirmed'],
        opportunity,
      })

      toast.success('Document deleted successfully.')
    },
  })
}
