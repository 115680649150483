import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'

async function fetchMessages(loadId) {
  const endpoint = `${apiUri}/loads/${loadId}/messages`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useMessages({ loadId }) {
  return useQuery(['messages', loadId], () => fetchMessages(loadId), {
    enabled: Boolean(loadId),
  })
}
