import clsx from 'clsx'
import {
  type DetailedHTMLProps,
  type ForwardedRef,
  forwardRef,
  type InputHTMLAttributes,
  type MouseEventHandler,
} from 'react'

interface CheckboxFieldProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string
  hintText?: string
  label?: string | JSX.Element
  labelClassName?: string
  name: string
  onHintClick?: MouseEventHandler<HTMLDivElement>
}

export const CheckboxInput = forwardRef(
  (
    {
      className = 'checkbox',
      hintText,
      label,
      labelClassName,
      name,
      onHintClick,
      ...rest
    }: CheckboxFieldProps,
    ref?: ForwardedRef<HTMLInputElement>,
  ) => {
    const labelContent = label ? (
      <label className={clsx('checkbox__label', labelClassName)} htmlFor={name}>
        {label}
      </label>
    ) : null

    return (
      <div className={className}>
        <input
          className="checkbox__input"
          id={name}
          name={name}
          ref={ref}
          type="checkbox"
          {...rest}
        />

        <label className="checkbox__display" htmlFor={name} />

        {labelContent}

        {hintText && (
          <div className="checkbox__hint" onClick={onHintClick}>
            {hintText}
          </div>
        )}
      </div>
    )
  },
)
