import clsx from 'clsx'
import { useParams } from 'react-router-dom'

import { type Opportunity } from '../../types/Opportunity'
import { getBidExpiration } from '../opportunities/OpportunityCard'
import ActionsBanner from '../shared/ActionsBanner'
import { ExpirationNotice } from '../shared/ExpirationNotice'
import QuoteForm from '../shared/QuoteForm'
import ShipmentDetails from '../shared/ShipmentDetails'
import ShipperCanceledOverlay from '../ShipperCanceledOverlay'

type Props = {
  isActive: boolean
  onClick: () => void
  openMessages: () => void
  opportunity: Opportunity
}

export const QuotingCard = ({
  isActive,
  onClick,
  openMessages,
  opportunity,
}: Props) => {
  const { isArchived, isCanceled, status } = opportunity
  const { mode } = useParams()
  const isLost = mode === 'lost' || status === 'lost'
  const { bidExpirationDate, isExpired } = getBidExpiration(opportunity)
  return (
    <article
      className={clsx('shipment-item', {
        'shipment-item--selected': isActive,
      })}
      onClick={onClick}
    >
      {bidExpirationDate && (
        <ExpirationNotice isActive={isActive} page={'opportunity'}>
          {isExpired ? 'Bidding expired on' : 'Bidding expires on '}{' '}
          {bidExpirationDate}
        </ExpirationNotice>
      )}
      <div className="shipment-item__initial">
        <ActionsBanner
          openMessages={openMessages}
          opportunity={opportunity}
          isExpired={isExpired}
        />
        <ShipmentDetails opportunity={opportunity} />
      </div>

      {isActive && !isLost && (
        <QuoteForm hasQuoted opportunity={opportunity} isExpired={isExpired} />
      )}

      {isCanceled && (
        <ShipperCanceledOverlay
          canArchive={!isArchived}
          opportunity={opportunity}
        />
      )}
    </article>
  )
}
