import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  setDataByPage,
} from '@fv/client-core'

import { apiUri } from '../../constants'
import { bucketCountKeys } from '../opportunities/useBucketCounts'

async function archiveCanceledOpportunities({ carrierId }) {
  const endpoint = `${apiUri}/opportunities/canceled`
  const options = buildFetchOptionsWithAuth({
    method: 'DELETE',
    body: JSON.stringify({ carrierId }),
  })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useArchiveCanceledOpportunities() {
  const queryClient = useQueryClient()

  return useMutation(archiveCanceledOpportunities, {
    onSuccess: () => {
      queryClient.setQueriesData(
        ['opportunities'],
        setDataByPage(opps => opps.filter(o => !o.isCanceled)),
      )

      queryClient.setQueriesData(['quoteRequest'], prev =>
        prev?.filter(o => !o.isCanceled),
      )

      toast.success('Removed canceled shipments successfully.')
      queryClient.invalidateQueries(['opportunities', 'archived'])
      queryClient.invalidateQueries(bucketCountKeys.all)
    },
  })
}
