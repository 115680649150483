import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import Nav from '../layout/Nav'

const AppLayout = ({ children }) => {
  const { pathname } = useLocation()

  return (
    <div
      className={clsx('grid-c-portal', {
        c2: pathname === '/settings',
      })}
    >
      <Nav />
      {children}
    </div>
  )
}

AppLayout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default AppLayout
