import { useState } from 'react'

import { LoadMore, useFvNavigate } from '@fv/client-components'

import { useMessagingControls } from '../../hooks/messages'
import { useActiveId, useOpportunitySearch } from '../../hooks/opportunities'
import { useQueryParam } from '../../hooks/settings'
import MessagingPage from '../messaging/MessagingPage'
import SearchForm from './SearchForm'
import SearchResults from './SearchResults'

const SearchPage = () => {
  const navigate = useFvNavigate()
  const messagingControls = useMessagingControls()
  const queryParam = useQueryParam('text') ?? ''
  const searchText = decodeURIComponent(queryParam).trim()

  const [value, setValue] = useState(searchText)
  const searchQuery = useOpportunitySearch(searchText.trim())
  const results = (searchQuery.data?.pages || []).reduce(
    (all, { data }) => all.concat(...data),
    [],
  )

  const [activeId, setActiveId] = useActiveId(results)
  const opportunity = results.find(r => r.loadId === activeId)

  function onValidSubmit() {
    if (searchQuery.isFetching || !value.trim()) return
    const nextSearch = encodeURIComponent(value.trim())
    navigate(`/search?text=${nextSearch}`)
  }

  return (
    <>
      <main>
        <header className="header-divider">Search</header>
        <SearchForm
          isLoading={searchQuery.isFetching}
          onFieldChange={e => setValue(e.target.value)}
          onValidSubmit={onValidSubmit}
          value={value}
        />

        <SearchResults
          activeId={activeId}
          data={results}
          openMessages={messagingControls.open}
          setActiveId={setActiveId}
        />

        <LoadMore
          fetchNextPage={searchQuery.fetchNextPage}
          hasNextPage={searchQuery.hasNextPage}
          isLoading={searchQuery.isFetchingNextPage}
        />
      </main>

      <MessagingPage {...messagingControls} opportunity={opportunity} />
    </>
  )
}

export default SearchPage
