import { Icon, Tooltip } from '@fv/client-components'

import { usePrettyNumber } from '../hooks/settings'

type SelectedPriceDisplayProps = {
  amount: number
  currency: string
  isLowest: boolean
  showComparison: boolean
}

const SelectedPriceDisplay = ({
  amount,
  currency,
  isLowest,
  showComparison,
}: SelectedPriceDisplayProps) => {
  const prettyNumber = usePrettyNumber()

  const { dollars, cents } = dollarsAndCents({
    amount,
    prettyNumber,
  })

  return (
    <div className="quoting-price-indicator-ctn">
      {showComparison && (
        <Tooltip
          label={
            isLowest
              ? 'You have the lowest bid.'
              : 'Your bid is not the lowest.'
          }
        >
          <div className="quoting-price-indicator">
            <Icon
              className={
                isLowest ? 'color-success butted' : 'color-warning butted'
              }
              icon={isLowest ? 'arrow-down' : 'arrow-up'}
            />
          </div>
        </Tooltip>
      )}
      <span className="flex items-start">
        $<span className="dollars">{dollars}</span>
        <div className="cents">{cents}</div>
        <div className="price__currency">{currency.toUpperCase()}</div>
      </span>
    </div>
  )
}

function dollarsAndCents({
  amount,
  prettyNumber,
}: {
  amount: number
  prettyNumber: (value: string) => string
}) {
  const [dollars, cents] = String(amount?.toFixed(2)).split('.')

  return {
    dollars: dollars ? prettyNumber(dollars) : '0',
    cents: cents ? cents.padEnd(2, '0') : '00',
  }
}

export default SelectedPriceDisplay
