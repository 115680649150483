import { useQuery } from '@tanstack/react-query'
import LogRocket from 'logrocket'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  logrocketApp,
} from '@fv/client-core'

import { apiUri } from '../../constants'
import { type CarrierContext } from '../../types/Context'

async function fetchUserContext() {
  const endpoint = `${apiUri}/context`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUserContext(enabled = true) {
  return useQuery<CarrierContext>(['userContext'], fetchUserContext, {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onSuccess(data) {
      if (!logrocketApp) return

      const name = `${data.user.profile?.firstName ?? ''} ${
        data.user.profile?.lastName ?? ''
      }`.trim()

      LogRocket.identify(data.user._id, {
        ...(data.user.carrierId && { carrierId: data.user.carrierId }),
        email: data.user.email,
        ...(name && { name }),
      })
    },
  })
}
