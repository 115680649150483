import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri, supportMessage } from '../../constants'
import { useUserContext } from '../settings'

async function postMessage({ loadId, messageText }) {
  const endpoint = `${apiUri}/loads/${loadId}/messages`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ messageText }),
    method: 'POST',
  })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json // message
  throw response.errorMessage
}

export function useSendMessage(loadId) {
  const queryClient = useQueryClient()
  const userQuery = useUserContext()
  const { firstName, lastName } = userQuery.data?.user.profile || {}
  const name = `${firstName} ${lastName}`

  const onMutate = useCallback(
    dto => {
      queryClient.setQueryData(['messages', dto.loadId], (prev = []) =>
        prev.concat({
          _id: 'newMessage',
          createdDate: new Date().toISOString(),
          participants: [
            {
              name,
              isAuthor: true,
              role: 'carrier',
            },
          ],
          messageText: dto.messageText,
        }),
      )
    },
    [name, queryClient],
  )

  const mutation = useMutation(postMessage, { onMutate })

  const sendMessage = useCallback(
    ({ messageText, onSuccess }) => {
      mutation
        .mutateAsync({ loadId, messageText })
        .then(message => {
          onSuccess && onSuccess(message)
        })
        .catch(() => {
          toast.error(`Unable to send message, ${supportMessage}`)
          queryClient.setQueryData(['messages', loadId], (prev = []) =>
            prev.filter(m => m._id !== 'newMessage'),
          )
        })
    },
    [loadId, mutation, queryClient],
  )

  return { sendMessage, isSendingMessage: mutation.isLoading }
}
