import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'

import {
  FvButton,
  Icon,
  TextAreaField,
  TextField,
  ValidatedForm,
} from '@fv/client-components'

import { supportMessage } from '../../constants'
import { useConfirmLoad, useRejectLoad } from '../../hooks/loads'

const ConfirmationForm = ({ opportunity }) => {
  const { isLegacy, loadId, status } = opportunity
  const formRef = useRef()
  const confirmLoad = useConfirmLoad()
  const rejectLoad = useRejectLoad()
  const disabled = confirmLoad.isLoading || rejectLoad.isLoading

  const [values, setValues] = useState({
    message: '',
    pickupNumber: '',
  })

  function onValidSubmit() {
    if (disabled) return

    confirmLoad
      .mutateAsync({
        loadId,
        message: values.message,
        pickupNumber: values.pickupNumber,
      })
      .catch(() => {
        toast.error(`Unable to confirm load, ${supportMessage}`)
      })
  }

  function rejectAwardedLoad() {
    if (disabled) return

    if (
      window.confirm(
        'By rescinding your winning bid, you acknowledge you will no longer be taking this freight. The shipper will be notified and you will not be assigned to this particular load.',
      )
    ) {
      rejectLoad.mutateAsync({ loadId }).catch(() => {
        toast.error(`Unable to decline load, ${supportMessage}`)
      })
    }
  }

  return (
    <ValidatedForm onValidSubmit={onValidSubmit} ref={formRef}>
      <hr className="mb-4" />

      <div className="shipment-item-ancillary">
        <div className="confirmation-ctn">
          <p className="mb-0">Confirmation #</p>
          <div className="pinline mx-4 my-0" />
          <div className="input-group input-group--flex">
            <div className="input-group-addon">
              <Icon icon="hashtag" />
            </div>
            <TextField
              className="form-control form-control--shipment-number"
              disabled={disabled || status === 'confirmed'}
              maxLength={50}
              name="pickupNumber"
              onChange={e => {
                setValues(prev => ({
                  ...prev,
                  pickupNumber: e.target.value,
                }))
              }}
              required
              type="text"
              value={values.pickupNumber}
            />
          </div>
        </div>

        <div className="form-group mt-4">
          <TextAreaField
            className="form-control"
            disabled={disabled}
            maxLength={500}
            name="message"
            onChange={e => {
              setValues(prev => ({
                ...prev,
                message: e.target.value,
              }))
            }}
            placeholder="Include an optional message here."
            value={values.message}
          />
        </div>
        <div className="flex justify-end">
          {isLegacy === false && (
            <FvButton
              disabled={disabled}
              onClick={rejectAwardedLoad}
              type="button"
              theme="default"
              icon={rejectLoad.isLoading ? 'spinner' : 'ban'}
            >
              <span>Decline</span>
            </FvButton>
          )}

          <FvButton
            type="submit"
            disabled={disabled}
            theme="default"
            icon={confirmLoad.isLoading ? 'spinner' : 'shield-check'}
            iconClass={clsx({ 'color-success': !confirmLoad.isLoading })}
          >
            <span>Confirm</span>
          </FvButton>
        </div>
      </div>
    </ValidatedForm>
  )
}

ConfirmationForm.propTypes = {
  opportunity: PropTypes.shape({
    loadId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default ConfirmationForm
