import dayjs from 'dayjs'
import UAParser from 'ua-parser-js'

import { FvButton, Icon } from '@fv/client-components'

import { type UserSession } from '../../types/Context'

const parseUserAgent = (userAgent?: string) =>
  new UAParser(userAgent).getResult()

type SessionsProps = {
  revokeSession: (id: string | null) => void
  sessions: UserSession[]
}

const Sessions = ({ revokeSession, sessions }: SessionsProps) => {
  if (!sessions) return null

  return (
    <>
      <header className="header-divider">Active sessions</header>
      <ul className="general-list">
        {sessions.map(s => {
          const userAgent = parseUserAgent(s.userAgent)

          return (
            <li className="session" key={s._id}>
              <span className="mr-4">
                {userAgent?.browser?.name} {userAgent?.browser?.major} on{' '}
                {userAgent?.os?.name}
              </span>
              <span className="mr-4">
                {dayjs(s.lastUsed).format('MMM D, YYYY h:mm A')}
                <span className="text-xs">
                  {' '}
                  ({dayjs(s.lastUsed).fromNow()})
                </span>
              </span>
              <span className="mr-4">{s.ip}</span>
              <span className="ml-auto mr-4">
                {s.active ? (
                  <FvButton
                    onClick={e => {
                      revokeSession(null)
                    }}
                    icon="times"
                  >
                    <span>Log out</span>
                  </FvButton>
                ) : (
                  <FvButton
                    onClick={e => {
                      e.preventDefault()
                      revokeSession(s._id)
                    }}
                    icon="times"
                  >
                    <span>Revoke</span>
                  </FvButton>
                )}
              </span>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default Sessions
