import { logoutSession } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useUserContext } from '../../hooks/settings'
import ProfileForm from '../profile/ProfileForm'
import Sessions from './Sessions'

const revokeSession = (sessionId: string | null) => {
  logoutSession(sessionId, apiUri)
}

const SettingsPage = () => {
  const context = useUserContext()

  return (
    <main>
      <header className="header-divider">Settings</header>
      <ProfileForm />
      <Sessions
        revokeSession={revokeSession}
        sessions={context.data?.user?.sessions ?? []}
      />
    </main>
  )
}

export default SettingsPage
