import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useCarrierLiveEvents } from '../hooks/settings'
import ActivePage from './active/ActivePage'
import LoadingPage from './layout/LoadingPage'
import NotFoundPage from './layout/NotFoundPage'
import OpportunitiesPage from './opportunities/OpportunitiesPage'
import QuotingPage from './quoting/QuotingPage'
import SearchPage from './search/SearchPage'
import SettingsPage from './settings/SettingsPage'
import WonPage from './won/WonPage'
import DirectShipmentLoader from './DirectShipmentLoader'

const CarrierRoutes = ({ carrierId }) => {
  useCarrierLiveEvents(carrierId)

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="/" element={<Navigate to="opportunities" replace />} />

        <Route
          path="direct/:quoteRequestId"
          element={<DirectShipmentLoader />}
        />

        <Route path="opportunities" element={<OpportunitiesPage />}>
          <Route path=":mode" element={null} />
        </Route>

        <Route path="quoting" element={<QuotingPage />}>
          <Route path=":mode" element={null} />
        </Route>

        <Route path="active" element={<ActivePage />}>
          <Route path=":mode" element={null} />
        </Route>

        <Route path="search" element={<SearchPage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="won" element={<WonPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

export default CarrierRoutes
