import PropTypes from 'prop-types'

const PublicLayout = ({ children }) => (
  <div className="flex h-screen items-center">
    <div className="login-wrapper">{children}</div>
  </div>
)

PublicLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
}

export default PublicLayout
