import { focusManager } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export function useAppFocus() {
  const [isFocused, setFocused] = useState(focusManager.isFocused())

  useEffect(() => {
    focusManager.subscribe(() => {
      setFocused(focusManager.isFocused())
    })
  }, [])

  return isFocused
}
