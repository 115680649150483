import { Mode } from 'fs'

import { LocationAccessorialType } from '@fv/models'

import { EquipmentAccessorialType } from './EquipmentAccessorial'
import { EquipmentType } from './EquipmentType'
import { PackagingType } from './PackagingType'

export const locales = ['en-CA', 'en-MX', 'en-US'] as const
export type Locale = (typeof locales)[number]

export type AccessorialPrompt = {
  key: 'count' | 'max' | 'min' | 'size' | 'uom'
  label: string
  max?: number
  min?: number
  options?: Array<{
    label: string
    value: number | string
  }>
  promptType: 'boolean' | 'number' | 'string'
}

export type AccessorialTypeEnum = {
  key: EquipmentAccessorialType | LocationAccessorialType
  name: string
  prompts?: Array<AccessorialPrompt>
  type: 'equipment' | 'location'
}

export type EquipmentTypeEnum = {
  accessorialTypes: EquipmentAccessorialType[]
  key: EquipmentType
  mode: Mode
  name: string
  workflows: Mode[]
}

type PackagingTypeEnum = {
  key: PackagingType
  modes: Mode[]
  name: string
}
interface EnumOption {
  key: string
  name: string
}

interface EnumsDTO {
  locationType: EnumOption[]
  packagingTypeList: PackagingTypeEnum[]
  weightUOM: EnumOption[]
  modeType: EnumOption[]
  currencyType: EnumOption[]
  temperatureUOM: EnumOption[]
  accessorialTypes: (AccessorialTypeEnum & { mode: Mode })[]
  freightClasses: number[]
  equipmentTypes: EquipmentTypeEnum[]
  hazardClasses: Array<{ key: string; isValidSubClass: boolean }>
  packingGroups: EnumOption[]
}

export interface AppSettings {
  liveEventsUri: string
  locale: Locale
  enums: EnumsDTO
}
