import { useQueryClient } from '@tanstack/react-query'
import { type MutableRefObject, useCallback, useRef } from 'react'

import {
  type LiveEvent,
  useLiveEventsProcessor,
  useNavState,
} from '@fv/client-components'

import { apiUri } from '../../constants'
import { bucketCountKeys } from '../opportunities/useBucketCounts'
import { getStartDateForStatus } from '../opportunities/useOpportunities'
import { useAppSettings } from './useAppSettings'

export function useCarrierLiveEvents(carrierId?: string) {
  // Throttle API calls
  const addTimeout = useRef<NodeJS.Timeout>()
  const oppUpdateTimeout = useRef<NodeJS.Timeout>()
  const quoteUpdateTimeout = useRef<NodeJS.Timeout>()
  const queryClient = useQueryClient()
  const settingsQuery = useAppSettings()
  const notifyRefresh = useNavState(s => s.notifyRefresh)

  const debounce = (
    ref: MutableRefObject<NodeJS.Timeout | undefined>,
    cb: () => void,
    ms = 1000,
  ) => {
    if (ref.current) {
      clearTimeout(ref.current)
    }

    ref.current = setTimeout(() => cb(), ms)
  }

  const handleEvent = useCallback(
    (event: LiveEvent) => {
      const eventName: string = event.event

      if (eventName === 'message:update') {
        const { loadId, message } = event.data

        queryClient.setQueriesData(
          ['messages', loadId],
          (prev?: Array<{ _id: string }>) => {
            if (!prev) return prev

            return prev
              .filter(m => m._id !== message._id && m._id !== 'newMessage')
              .concat(message)
          },
        )
      }

      if (eventName === 'quote:remove') {
        const { loadId, quote } = event.data

        debounce(quoteUpdateTimeout, () => {
          queryClient.invalidateQueries(['quoteRequest', quote.quoteRequestId])
          queryClient.invalidateQueries(['opportunities'])
          queryClient.invalidateQueries(bucketCountKeys.all)
        })

        queryClient.setQueriesData(
          ['quotes', loadId],
          (prev?: Array<{ _id: string }>) =>
            prev?.filter(q => q._id !== quote._id),
        )
      }

      if (eventName === 'quote:update') {
        const { loadId, quote } = event.data

        debounce(quoteUpdateTimeout, () => {
          queryClient.invalidateQueries(['quoteRequest', quote.quoteRequestId])
          queryClient.invalidateQueries(['opportunities'])
        })

        queryClient.setQueriesData(
          ['quotes', loadId],
          (prev?: Array<{ _id: string }>) => {
            if (!prev) return prev

            const nextQuotes = prev.filter(q => q._id !== quote._id)

            if (quote.status === 'error') return nextQuotes
            return nextQuotes.concat(quote)
          },
        )
      }

      if (eventName === 'opportunity:add') {
        const { opportunity } = event.data
        const status = opportunity?.status ?? 'open'

        debounce(addTimeout, () => {
          queryClient.invalidateQueries(['opportunities', status])
          queryClient.invalidateQueries(['opportunities', 'searchResults'])

          queryClient.invalidateQueries(
            bucketCountKeys.byStartDate(getStartDateForStatus(status)),
          )
        })
      }

      if (eventName === 'opportunity:update') {
        const { quoteRequestId, countsChanged } = event.data

        debounce(oppUpdateTimeout, () => {
          queryClient.invalidateQueries(['quoteRequest', quoteRequestId])
          queryClient.invalidateQueries(['opportunities'])
          countsChanged && queryClient.invalidateQueries(bucketCountKeys.all)
        })
      }

      if (eventName === 'system:reset') {
        notifyRefresh()
      }
    },
    [queryClient, notifyRefresh],
  )

  useLiveEventsProcessor({
    authenticateUri:
      carrierId && `${apiUri}/live-events?carrierId=${carrierId}`,
    handleEvent,
    liveEventsUri: settingsQuery.data?.liveEventsUri,
  })
}
