import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

import { FvButton, Icon, TextField, ValidatedForm } from '@fv/client-components'

const LoginForm = ({ errors, fields, onFieldChange, onSubmit }) => {
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <ValidatedForm onValidSubmit={onSubmit} className="">
      <label className="label">Send me a login link</label>
      <div className="input-group input-group--flex">
        <TextField
          ref={inputRef}
          name="email"
          type="email"
          error={errors.email}
          className="form-control"
          placeholder="Enter your email"
          onChange={onFieldChange}
          value={fields.email}
          required
          maxLength={200}
          autoFocus
        />

        <FvButton type="submit" theme="default" className="-ml-px">
          <Icon icon="check" />
          <span>Submit</span>
        </FvButton>
      </div>
    </ValidatedForm>
  )
}

LoginForm.propTypes = {
  errors: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default LoginForm
