import ProfileForm from './ProfileForm'

const ProfileModalView = () => (
  <div className="user-info-first-time-ctn">
    <p>
      Please fill out your first name, last name and your job role. You only
      have to do this once.
    </p>
    <ProfileForm className="user-information-ctn user-information-ctn--modal mt-6" />
  </div>
)

export default ProfileModalView
