import {
  vEmailOrEmpty,
  vObject,
  vOmit,
  vOptional,
  vOutput,
  vString,
} from '../validation'
import {
  vCountryUpper,
  vPhoneExt,
  vPhoneNumber,
} from '../validation/schema/custom'
import { addressBaseSchema } from './address'

export const billToSchema = vObject({
  ...addressBaseSchema.entries,
  _id: vOptional(vString()),
  company: vString({ minLength: 1 }),
  contactName: vOptional(vString()),
  contactPhone: vOptional(vPhoneNumber()),
  contactPhoneExt: vOptional(vPhoneExt()),
  contactEmail: vOptional(vEmailOrEmpty),
  accountNumber: vOptional(vString()),
})

export type IBillTo = vOutput<typeof billToSchema>

export const accountBillToSchema = vObject({
  ...vOmit(billToSchema, ['accountNumber']).entries,
  contactName: vString({ minLength: 1 }),
  country: vCountryUpper,
})
export type AccountBillTo = vOutput<typeof accountBillToSchema>

export const accountBillToRequestSchema = vObject({
  ...vOmit(accountBillToSchema, ['_id']).entries,
})
export type AccountBillToRequestModel = vOutput<
  typeof accountBillToRequestSchema
>
