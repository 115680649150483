import PropTypes from 'prop-types'

import { Icon } from '@fv/client-components'

const EmptyList = ({ busy, message }) => (
  <div className="box box--help">
    <p className="icon-message mb-0">
      <Icon
        icon={busy ? 'spinner' : 'hand-paper'}
        className="fa-fw color-dark"
      />
      {busy ? 'Loading...' : message}
    </p>
  </div>
)

EmptyList.propTypes = {
  busy: PropTypes.bool,
  message: PropTypes.string.isRequired,
}

export default EmptyList
