import clsx from 'clsx'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { type Opportunity } from '../../types/Opportunity'
import ActionsBanner from '../shared/ActionsBanner'
import { ExpirationNotice } from '../shared/ExpirationNotice'
import QuoteForm from '../shared/QuoteForm'
import ShipmentDetails from '../shared/ShipmentDetails'
import ShipperCanceledOverlay from '../ShipperCanceledOverlay'

type Props = {
  isActive: boolean
  onClick: () => void
  openMessages: () => void
  opportunity: Opportunity
}

export const OpportunityCard = ({
  isActive,
  onClick,
  openMessages,
  opportunity,
}: Props) => {
  const [isShowingForm, setShowingForm] = useState(false)
  useEffect(() => {
    if (!isActive) setShowingForm(false)
  }, [isActive])

  const { isArchived, isCanceled } = opportunity
  const toggleForm = () => setShowingForm(s => !s)
  const { bidExpirationDate, isExpired } = getBidExpiration(opportunity)

  return (
    <article
      className={clsx('shipment-item', {
        'shipment-item--selected': isActive,
      })}
      onClick={onClick}
    >
      {bidExpirationDate && (
        <ExpirationNotice isActive={isActive} page={'opportunity'}>
          {isExpired ? 'Expired on' : 'Expires on '} {bidExpirationDate}
        </ExpirationNotice>
      )}
      <div className="shipment-item__initial">
        <ShipmentDetails opportunity={opportunity} />

        <ActionsBanner
          openMessages={openMessages}
          opportunity={opportunity}
          toggleQuoteForm={toggleForm}
          isExpired={isExpired}
        />
      </div>

      {isShowingForm && (
        <QuoteForm opportunity={opportunity} isExpired={isExpired} />
      )}

      {isCanceled && (
        <ShipperCanceledOverlay
          canArchive={!isArchived}
          opportunity={opportunity}
        />
      )}
    </article>
  )
}

export const getBidExpiration = (opportunity: Opportunity) => {
  const bidExpDate = opportunity?.bidExpiration?.utc
    ? dayjs.utc(opportunity.bidExpiration.utc)
    : undefined

  if (!bidExpDate) {
    return {
      isExpired: false,
    }
  }

  return {
    bidExpirationDate: bidExpDate.local().format('ddd MMM D / h:mm A'),
    isExpired: dayjs.utc().isAfter(bidExpDate),
  }
}
