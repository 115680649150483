import clsx from 'clsx'

import { FvNavLink, Icon, LoadMore } from '@fv/client-components'

import { useMessagingControls } from '../../hooks/messages'
import { useActiveId, useOpportunities } from '../../hooks/opportunities'
import MessagingPage from '../messaging/MessagingPage'
import EmptyList from '../shared/EmptyList'
import QuotingLegend from '../shared/QuotingLegend'
import WonCard from './WonCard'

const getLinkClass = ({ isActive }) => {
  return clsx('standard-link standard-link--with-icon', {
    'standard-link--active': isActive,
  })
}

const WonPage = () => {
  const listQuery = useOpportunities('awarded')
  const messagingControls = useMessagingControls()

  const [activeId, setActiveId] = useActiveId(listQuery.data)
  const opportunity = listQuery.data?.find(r => r.loadId === activeId)

  return (
    <>
      <main>
        <header className="page-header">
          <nav className="link-row link-row--opportunities-nav">
            <FvNavLink className={getLinkClass} to="/won">
              <Icon icon="trophy" transform="down-.5" />
              <span>Awaiting confirmation</span>
            </FvNavLink>
          </nav>
          <QuotingLegend />
        </header>

        {listQuery.data?.length > 0 ? (
          listQuery.data.map(opportunity => (
            <WonCard
              isActive={opportunity.loadId === activeId}
              key={opportunity.loadId}
              onClick={() => setActiveId(opportunity.loadId)}
              openMessages={messagingControls.open}
              opportunity={opportunity}
            />
          ))
        ) : (
          <EmptyList
            message="There are no won shipments in your list."
            busy={listQuery.isLoading}
          />
        )}

        <LoadMore
          fetchNextPage={listQuery.fetchNextPage}
          hasNextPage={listQuery.hasNextPage}
          isLoading={listQuery.isFetchingNextPage}
        />
      </main>

      <MessagingPage {...messagingControls} opportunity={opportunity} />
    </>
  )
}

export default WonPage
