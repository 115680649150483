import clsx from 'clsx'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

const DateDisplay = ({ className, containerClassName, type, value }) => {
  if (!value) return <span className="empty-date">No date</span>

  const day = dayjs(value).format('DD')
  const month = dayjs(value).format('MMM')

  return (
    <div className={containerClassName}>
      <div
        className={clsx('calendar-icon', className, {
          'calendar-icon--delivery': type === 'destination',
          'calendar-icon--stop': type === 'stop',
        })}
      >
        <div className="calendar-icon__month">{month}</div>
        <div className="calendar-icon__day">{day}</div>
      </div>
    </div>
  )
}

DateDisplay.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  type: PropTypes.oneOf(['origin', 'stop', 'destination']).isRequired,
  value: PropTypes.string,
}

export default DateDisplay
