import { vOutput, vPicklist } from '../validation'

export const rollLabelSizes = [
  '4x8',
  '4x6',
  '3x5',
  '3x4',
  '4x2.125',
  '4x1',
] as const
export const sheetLabelSizes = [
  '8.5x11',
  '5.5x8.5',
  '4x3.3',
  '4x2',
  '3.5x5',
] as const

export const labelTypes = ['zpl', 'pdf'] as const
export type LabelType = (typeof labelTypes)[number]

export const labelSizes = [...rollLabelSizes, ...sheetLabelSizes] as const
export const labelSizeSchema = vPicklist(labelSizes)
export const labelCountSchema = vPicklist([1, 2, 4])
export type LabelSize = vOutput<typeof labelSizeSchema>
export type LabelCount = vOutput<typeof labelCountSchema>
