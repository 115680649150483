import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'

import {
  FvButton,
  Icon,
  TextAreaField,
  ValidatedForm,
} from '@fv/client-components'

import { useMessages, useSendMessage } from '../../hooks/messages'
import Branding from '../shared/Branding'
import EmptyList from '../shared/EmptyList'
import MessageRow from './MessageRow'

const MessagingPage = ({ close = null, isOpen = false, opportunity }) => {
  const [fields, setFields] = useState({ text: '' })
  const formRef = useRef()

  const { isCanceled, loadId } = opportunity ?? {}
  const messagesQuery = useMessages({ loadId })
  const { sendMessage } = useSendMessage(loadId)

  useEffect(() => {
    resetForm()
  }, [loadId])

  const messages = useMemo(() => {
    if (!messagesQuery.data) return []

    return messagesQuery.data
      .map(m => ({
        ...m,
        author: m.participants.find(p => p.isAuthor),
      }))
      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
  }, [messagesQuery.data])

  if (!opportunity || messagesQuery.isLoading) {
    return (
      <aside className={clsx({ flyout: isOpen })}>
        <EmptyList
          busy={messagesQuery.isFetching}
          message={
            messagesQuery.isFetching ? 'Loading...' : 'No messages available.'
          }
        />
        <Branding />
      </aside>
    )
  }

  async function onValidSubmit() {
    sendMessage({ messageText: fields.text.trim() })
    resetForm()
  }

  function onFieldChanged(e) {
    const name = e.target.name
    const value = e.target.value
    setFields(f => ({ ...f, [name]: value }))
  }

  function resetForm() {
    setFields({ text: '' })
    formRef.current?.reset()
  }

  return (
    <aside className={clsx({ flyout: isOpen })}>
      {!isCanceled && (
        <ValidatedForm ref={formRef} onValidSubmit={onValidSubmit}>
          <div className="form-group form-group--send-message">
            <div className="input-group input-group--flex flex-auto">
              <TextAreaField
                className="form-control form-control--height-transition"
                disabled={sendMessage.isLoading}
                maxLength={500}
                name="text"
                onChange={onFieldChanged}
                required
                rows="1"
                value={fields.text}
              />
              <FvButton
                theme="default"
                className="-mx-px"
                disabled={sendMessage.isLoading}
                type="submit"
                icon={sendMessage.isLoading ? 'spinner' : 'paper-plane'}
              >
                <span>Send</span>
              </FvButton>
            </div>
            <FvButton type="button" onClick={close} icon="times" />
          </div>
        </ValidatedForm>
      )}

      {messages.length ? (
        messages.map(m => <MessageRow key={m._id} message={m} />)
      ) : (
        <EmptyList message="No messages available." />
      )}

      <Branding />
    </aside>
  )
}

MessagingPage.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  opportunity: PropTypes.shape({
    isCanceled: PropTypes.bool,
    loadId: PropTypes.string.isRequired,
    quoteRequestId: PropTypes.string.isRequired,
  }),
}

export default MessagingPage
