import { Icon } from '@fv/client-components'

import { useBucketCounts } from '../hooks/opportunities'

type NumberInBucketProps = {
  status: 'awarded' | 'confirmed' | 'offered' | 'open'
}

const NumberInBucket = ({ status }: NumberInBucketProps) => {
  const countsQuery = useBucketCounts()
  const itemCount = countsQuery.data?.[status]

  return (
    <div className="counter counter--side-nav" role="status">
      {countsQuery.isLoading ? <Icon icon="spinner" /> : itemCount}
    </div>
  )
}

export default NumberInBucket
