import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  FvLinkButton,
  FvNavLink,
  Icon,
  LoadMore,
  SelectField,
} from '@fv/client-components'

import { useMessagingControls } from '../../hooks/messages'
import { useActiveId, useOpportunities } from '../../hooks/opportunities'
import {
  type OpportunityStatus,
  type OptSortType,
} from '../../hooks/opportunities/useOpportunities'
import MessagingPage from '../messaging/MessagingPage'
import EmptyList from '../shared/EmptyList'
import { OpportunityCard } from './OpportunityCard'

const getLinkClass = ({ isActive }: { isActive: boolean }) => {
  return clsx('standard-link standard-link--with-icon', {
    'standard-link--active': isActive,
  })
}

const options = [
  { text: 'Sort by pickup date', value: 'pickupDate' },
  {
    text: 'Sort by bid expiration date',
    value: 'bidExpirationDate',
  },
]

const OpportunitiesPage = () => {
  const { mode = 'open' } = useParams() // open, declined
  const [sortBy, setSortBy] = useState<OptSortType>('pickupDate')
  const [sortOrder, setSortOrder] = useState('ASC')
  const listQuery = useOpportunities(
    mode as OpportunityStatus,
    sortBy,
    sortOrder.includes('ASC') ? 1 : -1,
  )
  const messagingControls = useMessagingControls()
  const [activeId, setActiveId] = useActiveId(listQuery.data ?? [])
  const opportunity = listQuery.data?.find(o => o.loadId === activeId)

  const sortedOpportunities = useMemo(() => {
    if (!listQuery.data) return []

    return listQuery.data
  }, [listQuery.data])

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'ASC' ? 'DESC' : 'ASC'))
  }

  useEffect(() => {
    if (listQuery.data) {
      listQuery.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder])

  return (
    <>
      <main>
        <header className="page-header">
          <nav className="flex flex-1 divide-x divide-solid divide-[#ccc] [&>*]:px-4">
            <div className="first:pl-0">
              <SelectField
                className="form-control form-control--select w-auto"
                name="sortOption"
                id="sortOption"
                options={options}
                onChange={e => setSortBy(e.target.value as OptSortType)}
                value={sortBy}
              />
            </div>

            <FvNavLink className={getLinkClass} end to="/opportunities">
              <Icon icon="marker" />
              <span>Open</span>
            </FvNavLink>
            <FvNavLink
              className={getLinkClass}
              end
              to="/opportunities/declined"
            >
              <Icon icon="ban" />
              <span>Declined</span>
            </FvNavLink>
          </nav>
          <span className="mr-2">Sort:</span>
          <Icon icon="sort-alt" />
          <FvLinkButton
            theme="callout"
            className="ml-1 mr-2"
            onClick={toggleSortOrder}
          >
            {sortOrder}
          </FvLinkButton>
        </header>

        {sortedOpportunities?.length > 0 ? (
          sortedOpportunities.map(opp => (
            <OpportunityCard
              isActive={opp.loadId === activeId}
              key={opp.loadId}
              onClick={() => setActiveId(opp.loadId)}
              openMessages={messagingControls.open}
              opportunity={opp}
            />
          ))
        ) : (
          <EmptyList
            message="There are no opportunities in your list."
            busy={listQuery.isLoading}
          />
        )}

        <LoadMore
          fetchNextPage={listQuery.fetchNextPage}
          hasNextPage={listQuery.hasNextPage}
          isLoading={listQuery.isFetchingNextPage}
        />
      </main>

      <MessagingPage {...messagingControls} opportunity={opportunity} />
    </>
  )
}

export default OpportunitiesPage
