import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'

async function fetchQuoteRequest(quoteRequestId) {
  const endpoint = `${apiUri}/opportunities/${quoteRequestId}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useQuoteRequest(quoteRequestId) {
  return useQuery(
    ['quoteRequest', quoteRequestId],
    () => fetchQuoteRequest(quoteRequestId),
    {
      enabled: Boolean(quoteRequestId),
    },
  )
}
