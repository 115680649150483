import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  FvLinkButton,
  FvNavLink,
  Icon,
  LoadMore,
  SelectField,
} from '@fv/client-components'

import { useMessagingControls } from '../../hooks/messages'
import { useActiveId, useOpportunities } from '../../hooks/opportunities'
import MessagingPage from '../messaging/MessagingPage'
import EmptyList from '../shared/EmptyList'
import QuotingLegend from '../shared/QuotingLegend'
import { QuotingCard } from './QuotingCard'

const getLinkClass = ({ isActive }) => {
  return clsx('standard-link standard-link--with-icon', {
    'standard-link--active': isActive,
  })
}
const options = [
  { text: 'Sort by pickup date', value: 'pickupDate' },
  {
    text: 'Sort by bid expiration date',
    value: 'bidExpirationDate',
  },
]
const QuotingPage = () => {
  const { mode = 'offered' } = useParams() // offered, lost
  const [sortBy, setSortOption] = useState({
    sortOption: 'pickupDate',
  })
  const [sortOrder, setSortOrder] = useState('ASC')
  const listQuery = useOpportunities(
    mode,
    sortBy.sortOption,
    sortOrder.includes('ASC') ? 1 : -1,
  )
  const messagingControls = useMessagingControls()

  const [activeId, setActiveId] = useActiveId(listQuery.data)
  const opportunity = listQuery.data?.find(r => r.loadId === activeId)

  const sortedOpportunities = listQuery.data ?? []

  const handleSortOption = criteria => {
    setSortOption(prev => ({
      ...prev,
      sortOption: criteria,
    }))
  }
  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'ASC' ? 'DESC' : 'ASC'))
  }

  useEffect(() => {
    if (listQuery.data) {
      listQuery.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy.sortOption, sortOrder])

  return (
    <>
      <main>
        <header className="page-header">
          <nav className="flex flex-1 divide-x divide-solid divide-[#ccc] [&>*]:px-4">
            <div className="first:pl-0">
              <SelectField
                className="form-control form-control--select w-auto"
                name="sortOption"
                id="sortOption"
                options={options}
                onChange={e => handleSortOption(e.target.value)}
                value={sortBy.sortOption}
              />
            </div>

            <FvNavLink className={getLinkClass} end to="/quoting">
              <Icon icon="dollar-sign" />
              <span>Offered</span>
            </FvNavLink>
            <FvNavLink className={getLinkClass} end to="/quoting/lost">
              <Icon icon="ban" />
              <span>Lost</span>
            </FvNavLink>
          </nav>
          <span className="mr-2">Sort:</span>
          <Icon icon="sort-alt" />
          <FvLinkButton
            theme="callout"
            className="ml-1 mr-2"
            onClick={toggleSortOrder}
          >
            {sortOrder}
          </FvLinkButton>
          <QuotingLegend />
        </header>

        {sortedOpportunities?.length > 0 ? (
          sortedOpportunities.map(opportunity => (
            <QuotingCard
              isActive={opportunity.loadId === activeId}
              key={opportunity.loadId}
              onClick={() => setActiveId(opportunity.loadId)}
              openMessages={messagingControls.open}
              opportunity={opportunity}
            />
          ))
        ) : (
          <EmptyList
            message={
              mode === 'lost'
                ? 'You have not lost any opportunities.'
                : 'You are not quoting on any shipments.'
            }
            busy={listQuery.isLoading}
          />
        )}

        <LoadMore
          fetchNextPage={listQuery.fetchNextPage}
          hasNextPage={listQuery.hasNextPage}
          isLoading={listQuery.isFetchingNextPage}
        />
      </main>

      <MessagingPage {...messagingControls} opportunity={opportunity} />
    </>
  )
}

export default QuotingPage
