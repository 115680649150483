import clsx from 'clsx'

import { FvNavLink, Icon } from '@fv/client-components'

import { useUserContext } from '../../hooks/settings'
import NumberInBucket from '../NumberInBucket'

const getLinkClass = ({ isActive }) => {
  return clsx('standard-link standard-link--side-nav-link', {
    'side-nav-link--active': isActive,
  })
}

const Nav = () => {
  const context = useUserContext()

  return (
    <div className="side-nav-outer">
      <nav className="side-nav">
        <ul className="side-nav-links flex-1">
          <li>
            <FvNavLink className={getLinkClass} to="/search">
              <Icon icon="search" className="fa-fw color-dark" />
              <span className="desktop">Search for something</span>
              <span className="tablet">Search</span>
            </FvNavLink>
          </li>
          <li>
            <FvNavLink className={getLinkClass} to="/opportunities">
              <Icon icon="bullseye-arrow" className="fa-fw color-dark" />
              <span className="desktop">Opportunities</span>
              <span className="tablet">Opps</span>
              <NumberInBucket status="open" />
            </FvNavLink>
          </li>
          <li>
            <FvNavLink className={getLinkClass} to="/quoting">
              <Icon icon="badge-dollar" className="fa-fw color-dark" />
              <span className="desktop">Currently quoting</span>
              <span className="tablet">Quoting</span>
              <NumberInBucket status="offered" />
            </FvNavLink>
          </li>
          <li>
            <FvNavLink className={getLinkClass} to="/won">
              <Icon icon="trophy" className="fa-fw color-dark" />
              <span className="desktop">Shipments won</span>
              <span className="tablet">Won</span>
              <NumberInBucket status="awarded" />
            </FvNavLink>
          </li>
          <li>
            <FvNavLink className={getLinkClass} to="/active">
              <Icon icon="truck" className="fa-fw color-dark" />
              <span className="desktop">Active shipments</span>
              <span className="tablet">Active</span>
              <NumberInBucket status="confirmed" />
            </FvNavLink>
          </li>
          <li>
            <FvNavLink className={getLinkClass} to="/settings">
              <Icon icon="cog" className="fa-fw color-dark" />
              <span className="nav-username-text">
                {context.data?.user?.email}
              </span>
            </FvNavLink>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Nav
