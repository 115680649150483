import PropTypes from 'prop-types'

import { Icon } from '@fv/client-components'

const MessageFlyOut = ({ openMessages }) => {
  return (
    <a
      className="standard-link standard-link--message-flyout"
      onClick={openMessages}
    >
      <Icon icon="comment-alt-lines" transform="down-.5" />
      <span className="messages-text">Messages</span>
    </a>
  )
}

MessageFlyOut.propTypes = {
  openMessages: PropTypes.func.isRequired,
}

export default MessageFlyOut
