import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type Quote } from '@fv/client-types'

import { apiUri } from '../../constants'
import { useCacheHelpers } from '../opportunities'
import { bucketCountKeys } from '../opportunities/useBucketCounts'

async function deleteQuote({
  loadId,
  quoteId,
}: {
  loadId: string
  quoteId: string
}) {
  const endpoint = `${apiUri}/loads/${loadId}/quotes/${quoteId}`
  const options = buildFetchOptionsWithAuth({ method: 'DELETE' })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return { loadId, quoteId }
  throw response.errorMessage
}

export function useRetractQuote(quoteRequestId: string) {
  const { removeFromList, updateCachedOpportunity } = useCacheHelpers()
  const queryClient = useQueryClient()

  return useMutation(deleteQuote, {
    onSuccess: ({ loadId, quoteId }) => {
      removeFromList({
        loadId,
        queryKey: ['opportunities', 'offered'],
      })

      let hasMoreQuotes = false

      queryClient.setQueryData(['quotes', loadId], (prev?: Quote[]) => {
        if (!prev) return []
        hasMoreQuotes = prev.length > 1
        return prev.filter(q => q._id !== quoteId)
      })

      if (!hasMoreQuotes) {
        updateCachedOpportunity({
          opportunity: {
            loadId,
            quoteRequestId,
            status: 'open',
          },
        })
      }

      toast.success('Quote retracted successfully.')

      setTimeout(() => {
        queryClient.invalidateQueries(['messages', loadId])
        queryClient.invalidateQueries(['opportunities', 'searchResults'])
        queryClient.invalidateQueries(['quoteRequest', quoteRequestId])
        queryClient.invalidateQueries(bucketCountKeys.all)
      })
    },
  })
}
