import { Fragment } from 'react'

import { DelimitedContent, Icon, Slash, Tooltip } from '@fv/client-components'
import { getLocAccessorials } from '@fv/client-core'
import { type LocationAccessorialDTO } from '@fv/models'

import { useTypeNames } from '../../hooks/settings'

type StopAccessorialsProps = {
  accessorials?: LocationAccessorialDTO[]
}

const StopAccessorials = ({ accessorials = [] }: StopAccessorialsProps) => {
  const typeNames = useTypeNames()
  const accessorialList = getLocAccessorials({ accessorials }, typeNames)

  return (
    <span>
      <DelimitedContent delimiter={<Slash />}>
        {accessorialList?.map(a => {
          return (
            <Fragment key={a.key}>
              {a.appointmentTime ? (
                <Tooltip label={a.appointmentTime}>
                  <span className="standard-link">
                    <Icon icon="clock" transform="down-.5" />
                    <span>Appt. required</span>
                  </span>
                </Tooltip>
              ) : (
                <span>{a.name}</span>
              )}
            </Fragment>
          )
        })}
      </DelimitedContent>
    </span>
  )
}

export default StopAccessorials
