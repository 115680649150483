import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type AppSettings } from '@fv/client-types'

import { apiUri } from '../../constants'

export type CarrierAppSettings = AppSettings & {
  requiresQuoteNumber?: boolean
  allowAPIQuoteEdit?: boolean
}

async function fetchAppSettings(): Promise<CarrierAppSettings> {
  const endpoint = `${apiUri}/app-settings`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useAppSettings(enabled = true) {
  return useQuery(['appSettings'], fetchAppSettings, {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
