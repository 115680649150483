import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useCacheHelpers } from '../opportunities'

async function undeclineOpportunity({ loadId }) {
  const endpoint = `${apiUri}/loads/${loadId}/decline`
  const options = buildFetchOptionsWithAuth({ method: 'DELETE' })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json // updatedOpportunity
  throw response.errorMessage
}

export function useUndeclineOpportunity() {
  const { removeFromList, updateCachedOpportunity } = useCacheHelpers()

  return useMutation(undeclineOpportunity, {
    onSuccess: opportunity => {
      updateCachedOpportunity({ opportunity })
      removeFromList({
        loadId: opportunity.loadId,
        queryKey: ['opportunities', 'declined'],
      })

      toast.success('Opportunity reopened successfully.')
    },
  })
}
