import clsx from 'clsx'
import PropTypes from 'prop-types'

import ActionsBanner from '../shared/ActionsBanner'
import ShipmentDetails from '../shared/ShipmentDetails'
import ShipperCanceledOverlay from '../ShipperCanceledOverlay'
import ConfirmationForm from './ConfirmationForm'

const WonCard = ({ isActive, onClick, openMessages, opportunity }) => {
  const { isArchived, isCanceled } = opportunity

  return (
    <article
      className={clsx('shipment-item', {
        'shipment-item--selected': isActive,
      })}
      onClick={onClick}
    >
      <div className="shipment-item__initial">
        <ActionsBanner openMessages={openMessages} opportunity={opportunity} />
        <ShipmentDetails opportunity={opportunity} />
      </div>

      {isActive && <ConfirmationForm opportunity={opportunity} />}

      {isCanceled && (
        <ShipperCanceledOverlay
          canArchive={!isArchived}
          opportunity={opportunity}
        />
      )}
    </article>
  )
}

WonCard.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  openMessages: PropTypes.func.isRequired,
  opportunity: PropTypes.shape({
    isArchived: PropTypes.bool,
    isCanceled: PropTypes.bool,
  }).isRequired,
}

export default WonCard
