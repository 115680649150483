import { useEffect } from 'react'

import { useFvNavigate } from '@fv/client-components'
import { getToken } from '@fv/client-core'

import { useAppSettings, useUserContext } from '../../hooks/settings'

// TODO: Keep route params when redirecting
const SessionGate = ({ children }) => {
  const navigate = useFvNavigate()
  const sessionToken = getToken()
  const contextQuery = useUserContext(!!sessionToken)
  const settingsQuery = useAppSettings(!!sessionToken)

  useEffect(() => {
    if (!sessionToken) navigate('/login', { replace: true })
  }, [navigate, sessionToken])

  const hasRequiredData =
    sessionToken && contextQuery.isSuccess && settingsQuery.isSuccess

  return hasRequiredData ? children : null
}

export default SessionGate
