import { useMemo, useState } from 'react'

export function useMessagingControls() {
  const [isOpen, setOpen] = useState(false)

  return useMemo(
    () => ({
      close: () => setOpen(false),
      isOpen,
      open: () => setOpen(true),
    }),
    [isOpen],
  )
}
